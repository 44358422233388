// React and libraries imports
import React, { useEffect, useState } from 'react';
import { Form, Col, Button, Spinner, Badge } from 'react-bootstrap';
import { pdfjs } from 'react-pdf';
import Select from 'react-select';

// Core components and utils imports
import Image from '../../../../../../../core-components/Image';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import {
  FileErrorMeaage,
  formatDocType,
  validateDoc,
  getFileHeader,
  validateImage
} from '../../../../../../../utils/utilities';
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import PasswordProtectedModal from '../../../PasswordProtectedModal';
import {
  RenderErrorUI,
  RenderUploadedFile
} from '../../../Education/EducationModals/AddEditUpload/CommonUploadComponent';

// Styles
import styles from './MultiUploadForm.module.scss';
import { ADDRESS_DOC_TYPE } from '../../../../../../../utils/commonConstant';
import { Input } from '@material-ui/core';

const MultiUploadForm = ({
  isMobileOnly,
  docUpload,
  setDocUpload,
  educationGradesheetUpload,
  deleteGradesheetUpload,
  isImageHavingError,
  getCurrentAddressTypeData,
  formatAddressToSingleLine
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [isCamHavingError, setIsCamHavingError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [maxFileError, setMaxFileError] = useState(false);
  const [passwordProtectedFiles, setPasswordProtectedFiles] = useState([]);
  const [passwordProtectedUploadedLength, setPasswordProtectedUploadedLength] = useState(0); // Store initial password protected length
  const [isEditing, setIsEditing] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherFieldValue, setOtherFieldValue] = useState('');

  useEffect(() => {
    if (passwordProtectedFiles.length) {
      setShowPasswordProtectedModal(true);
    }
  }, [passwordProtectedFiles]);

  const updateDocCopyAtIndex = ({
    link = '',
    error = null,
    loading = false,
    isPdf = true,
    password = null
  }) => {
    return {
      link,
      error,
      loading,
      isPdf,
      password
    };
  };

  const handleCamPhoto = () => {
    setTakePhoto(!takePhoto);
    setIsCamHavingError(false);
  };

  const handleFileUpload = async (formData, isPdf, index, file, resolve, isCam = false) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        if (!reader.result) return;

        try {
          const res = await educationGradesheetUpload({ formData, isPdf });
          let validationResult;
          let docCopy = {
            link: res.link,
            error: '',
            loading: false,
            isPdf: isPdf
          };
          const address = formatAddressToSingleLine(getCurrentAddressTypeData());
          if (!isPdf) {
            validationResult = await validateDoc(res.link, address);
            docCopy = { ...docCopy, ...validationResult };
          }

          if (isPdf) {
            validationResult = await validateDoc(res.link, address);
            try {
              const loadingTask = pdfjs.getDocument({
                data: reader.result,
                password: ''
              });

              await loadingTask.promise;
              resolve({ docCopy: { ...docCopy, ...validationResult }, index });
            } catch (error) {
              if (error.message === 'No password given') {
                resolve({
                  docCopy: {
                    ...docCopy,
                    fileName: file.name
                  },
                  index,
                  isPasswordProtected: true
                });
              } else {
                console.error('Error loading PDF document:', error);
              }
            }
          } else {
            resolve(isCam ? false : { docCopy, index });

            if (isCam) {
              handleCamPhoto();
              setDocUpload((prevDocUpload) => {
                const docCopy = [...prevDocUpload.proof_doc];
                docCopy.push({
                  link: res.link,
                  error: false,
                  isPdf: false
                });
                return {
                  ...prevDocUpload,
                  proof_doc: docCopy
                };
              });
            }
          }
        } catch (uploadError) {
          console.error('Upload error:', uploadError);
          const docCopy = [];
          docCopy[index] = {
            link: '',
            error: 'Upload failed. File format not valid.',
            loading: false,
            isPdf: isPdf,
            password: ''
          };
          resolve(docCopy);
        }
      };

      reader.onerror = (error) => {
        console.error('FileReader error:', error);
        resolve({
          link: '',
          error: 'Failed to read file.',
          loading: false,
          isPdf: isPdf
        });
      };
    } catch (e) {
      console.error('Unexpected error:', e);
      resolve({
        link: '',
        error: 'An unexpected error occurred.',
        loading: false,
        isPdf: isPdf
      });
    }
  };

  const handleFileSelect = async (e, type, index) => {
    const numberOfFiles = docUpload.proof_doc.length + e.target.files.length;

    if (numberOfFiles > 10) {
      setMaxFileError(true);
    } else {
      setMaxFileError(false);
      const promises = Array.from(e.target.files).map((file, ind) => {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];
          docCopy[index] = updateDocCopyAtIndex({ error: '', loading: true });
          return {
            ...prevDocUpload,
            proof_doc: docCopy
          };
        });
        return new Promise((resolve, reject) => {
          let fileType = '';
          var blob = file;
          var fileReader = new FileReader();
          fileReader.onloadend = () => {
            var arr = new Uint8Array(fileReader.result).subarray(0, 4);
            var header = '';
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Check the file signature against known types
            fileType = getFileHeader(header);
            const errMessage = validateImage(blob, fileType);
            const isPdf = fileType === 'application/pdf' ? true : false;
            if (!errMessage) {
              let formData = new FormData();
              formData.append(`file`, blob);
              formData.append(`file_type`, 'CHECK_DOCUMENT');
              handleFileUpload(formData, isPdf, index + ind, blob, resolve);
            } else {
              resolve({
                docCopy: updateDocCopyAtIndex({ error: FileErrorMeaage[errMessage], isPdf }),
                index: index + ind
              });
            }
          };

          if (blob instanceof Blob) {
            {
              /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
            }
            fileReader.readAsArrayBuffer(blob);
          } else {
            console.error(
              `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
            );
          }
        });
      });
      Promise.all(promises).then((files) => {
        /* Filters all files which are password protected*/
        const filesWithPassword = files.filter((file) => file?.isPasswordProtected);
        setPasswordProtectedFiles(filesWithPassword);
        setPasswordProtectedUploadedLength(filesWithPassword.length);

        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];

          files?.forEach((file) => {
            docCopy[file?.index] = {
              link: file?.docCopy?.link,
              error: file?.docCopy?.error,
              isPdf: file?.docCopy?.isPdf,
              originalValidity: file?.docType?.isValidDocument,
              isValidDocument: file?.docCopy?.isValidDocument,
              addressMatch: file?.docCopy?.addressMatch,
              documentTag: file?.docCopy?.isValidDocument ? file?.docCopy?.documentTag : '',
              originalTag: file?.docCopy?.documentTag,
              extractedDetails: file?.docCopy?.extractedDetails
            };
          });

          return {
            ...prevDocUpload,
            proof_doc: [...docCopy]
          };
        });
      });
    }
  };
  const renderUploadAddressDoc = () => {
    return (
      <Col lg={5} md={6} sm={11} xs={11}>
        <label className={styles.certificateInputFileContainer}>
          <div>
            <Image name='certificate_upload_enable.png' />
            <p>Add your address proof</p>
            <Form.Control
              id='formControlsFile'
              accept='image/*,application/pdf'
              type='file'
              label='File'
              multiple
              onChange={(e) => handleFileSelect(e, 'addressdoc', docUpload.proof_doc.length)}
            />
          </div>
        </label>
      </Col>
    );
  };

  const enablePhotoBtn = () => {
    let isImageHavingError = false;

    if (isMobileOnly) {
      for (let value of docUpload.proof_doc) {
        if (value.error) {
          isImageHavingError = true;
          break;
        }
      }
    }
    return isImageHavingError;
  };

  const handleImagePreview = (url) => {
    setImagePreviewUrl(url || '');
    setShowImagePreviewModal(!showImagePreviewModal);
  };

  const passwordProtectedFilesStateUpdate = () => {
    setPasswordProtectedFiles((prevPasswordFiles) => {
      const remainingProtectedFiles = [...prevPasswordFiles];
      remainingProtectedFiles.pop();
      if (remainingProtectedFiles.length == 0) {
        setShowPasswordProtectedModal(false);
      }
      return remainingProtectedFiles;
    });
  };

  const submitHandlerPasswordModal = async (password = '', link, index, validate) => {
    if (validate) {
      const address = formatAddressToSingleLine(getCurrentAddressTypeData());
      const validationResult = await validateDoc(link, address);
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.proof_doc];
        docCopy[index] = {
          ...docCopy[index],
          link,
          password,
          originalValidity: validationResult?.isValidDocument,
          isValidDocument: validationResult?.isValidDocument,
          isAddressMatch: validationResult?.addressMatch,
          documentTag: validationResult?.isValidDocument ? validationResult?.documentTag : '',
          originalTag: validationResult?.documentTag,
          extractedDetails: validationResult?.extractedDetails,
          addressMatchingPercentage: validationResult?.addressMatchingPercentage
        };
        return {
          ...prevDocUpload,
          proof_doc: docCopy
        };
      });
    } else {
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.proof_doc];
        docCopy[index] = updateDocCopyAtIndex({ link, password });
        return {
          ...prevDocUpload,
          proof_doc: docCopy
        };
      });
    }
    passwordProtectedFilesStateUpdate();
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      // If any of the password protected modal is closed, then we have save unprotected files/ unlocked files
      const passwordProtectedFilesIndices = passwordProtectedFiles.map((item) => item?.index);
      const newArray = docCopy.filter((_, index) => !passwordProtectedFilesIndices.includes(index));
      return {
        ...prevDocUpload,
        proof_doc: newArray
      };
    });
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const skipFileHandler = (index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy.splice(index, 1);
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });

    passwordProtectedFilesStateUpdate();
  };

  const clearError = (type, index) => {
    const addressDocCopy = [...docUpload.proof_doc];
    addressDocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...addressDocCopy]
    });
  };

  const handleDeleteImage = (type, index) => {
    const addressDocCopy = [...docUpload.proof_doc];
    addressDocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...addressDocCopy]
    });
    deleteGradesheetUpload(addressDocCopy);
  };

  const renderAddressDocHavingLength = () => {
    return docUpload.proof_doc.map((item, index) => {
      return item?.error ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderErrorUI
            handleClick={clearError}
            errorText={item.error}
            index={index}
            type='addressdoc'
          />
        </Col>
      ) : item?.loading ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <div className={styles.certificateInputFileContainer}>
            <Spinner animation='border' variant='primary' />
          </div>
        </Col>
      ) : item?.link ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={12}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderUploadedFile
            item={item}
            handleImagePreview={handleImagePreview}
            handleDeleteImage={handleDeleteImage}
            type='addressdoc'
            index={index}
            isPdf={item.isPdf}
          />
          {isEditing === item.link ? (
            <>
              {showOtherField ? (
                <Input
                  className='border rounded p-2'
                  value={otherFieldValue}
                  type='text'
                  placeholder='What document is this?'
                  onChange={(e) => setOtherFieldValue(e.target.value)}
                  onBlur={() => {
                    if (otherFieldValue.trim()) {
                      const tempDocUpload = [...docUpload.proof_doc];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: otherFieldValue,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, proof_doc: tempDocUpload });
                    }
                    setShowOtherField(false);
                    setIsEditing(null);
                    setOtherFieldValue('');
                  }}
                  autoFocus={true}
                />
              ) : (
                <Select
                  className='mb-2'
                  options={ADDRESS_DOC_TYPE}
                  menuPlacement='top'
                  value={
                    ADDRESS_DOC_TYPE.find(
                      (object) => formatDocType(object.label) === formatDocType(item.documentTag)
                    ) || null
                  }
                  onChange={(value) => {
                    if (value.value === 'others') {
                      setShowOtherField(true);
                    } else {
                      const tempDocUpload = [...docUpload.proof_doc];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: value.label,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, proof_doc: tempDocUpload });
                      setIsEditing(null);
                    }
                  }}
                  autoFocus={true}
                  blurInputOnSelect={false}
                  onBlur={() => setIsEditing(null)}
                />
              )}
            </>
          ) : (
            // Show the badge and edit button when not editing
            <div className='mb-2'>
              <Badge pill variant={item.documentTag ? 'success' : 'warning'} className='mr-1'>
                <div style={{ textTransform: 'capitalize' }}>
                  {item.documentTag || 'Invalid Doc'}
                </div>
              </Badge>
              <Image
                className={styles.editIcon}
                name='BgvIdEdit.png'
                data-testid='edit-button'
                onClick={() => setIsEditing(item.link)}
              />
            </div>
          )}
        </Col>
      ) : (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`address_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <label className={styles.certificateInputFileContainer}>
            <div>
              <Image name='certificate_upload_enable.png' />
              <p>Add your address proof</p>
              <Form.Control
                id='formControlsFile'
                accept='image/*,application/pdf'
                type='file'
                label='File'
                multiple
                onChange={(e) => handleFileSelect(e, 'addressdoc', index)}
              />
            </div>
          </label>
        </Col>
      );
    });
  };

  return (
    <>
      <Form.Row
        className={styles.Row}
        style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <Form.Label>
            Add your address proof<span style={{ color: 'red' }}>*</span>
          </Form.Label>
          {maxFileError && <p className={styles.maxFileError}>Maximum 10 files can be uploaded</p>}
        </Col>
        {isMobileOnly ? (
          <Col sm={12} xs={12}>
            <Form.Row>
              <Col sm={11} xs={11} className={styles.takePhotoBtnContainer}>
                <Button
                  className={styles.takePhotoBtn}
                  onClick={() => handleCamPhoto()}
                  disabled={enablePhotoBtn() && docUpload.proof_doc.length}
                >
                  Take Photo of proof
                </Button>
              </Col>
            </Form.Row>
          </Col>
        ) : null}
        <Col lg={10} md={12} sm={12} xs={12}>
          <Form.Row
            className={styles.Row}
            style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}
          >
            <>
              {docUpload.proof_doc.length ? renderAddressDocHavingLength() : null}
              {docUpload.proof_doc.length < 10 && !isImageHavingError()
                ? renderUploadAddressDoc()
                : null}
            </>
          </Form.Row>
        </Col>
      </Form.Row>
      {showImagePreviewModal && (
        <Form.Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <ImagePreviewModal
              showImagePreviewModal={showImagePreviewModal}
              handleImagePreview={handleImagePreview}
              imagePreviewUrl={imagePreviewUrl}
            />
          </Col>
        </Form.Row>
      )}
      {isMobileOnly && takePhoto && (
        <BGVCamera
          type='multiple'
          showBgvCameraModal={takePhoto}
          handleCameraModal={handleCamPhoto}
          //handleMultipleUpload={handleFileUpload}
          handleMultipleUpload={(formData, isPdf, index, setCamState, file) => {
            handleFileUpload(formData, isPdf, index, file, setCamState, true);
          }}
          isCameraUploadError={isCamHavingError}
          index={
            docUpload.proof_doc.length && !isImageHavingError()
              ? docUpload.proof_doc.length
              : docUpload.proof_doc.length && isImageHavingError()
              ? docUpload.proof_doc.length - 1
              : docUpload.proof_doc.length
          }
        />
      )}
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'employment'}
          SubOnHide={modalHide}
          skipFileHandler={skipFileHandler}
          passwordProtectedUploadedLength={passwordProtectedUploadedLength}
          passwordProtectedFiles={passwordProtectedFiles}
        />
      )}
    </>
  );
};

export default MultiUploadForm;
