import React, { useState, useEffect, useContext } from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import ordinal from 'ordinal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  EducationArr,
  AddressArr,
  IdentityArr,
  loggly_payment_type,
  SocialMediaConstants
} from '../../../../../utils/commonConstant';
import { calculateTDS, getCount, convertToIndianNumeration } from '../../../../../utils/utilities';
import styles from './Summary.module.scss';
import RazorpayButton from '../../../../../core-components/Razorpay/index';
import ConfirmationTdsModal from '../../../../../core-components/ConfirmationModalTds';
import CompanyTds from '../../../CompanyTds';
import {
  buyOrder,
} from '../../../../../api/company';
import Image from '../../../../../core-components/Image';
import { getEduPrice, calculateTotalPrice, getAddonPrice } from './SummaryUtils';
import { useSelector } from 'react-redux';
import { maskPriceForAdmins } from '../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';

function Summary(props) {
  const {
    step1Values,
    step2Values,
    step3Values,
    candidateConfig,
    packageType,
    costPerCandidate,
    isBulkUpload,
    bulkCandidates,
    setShowLoader
  } = props;

  const [totalPrice, setTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [insuffCredits, setInsuffCredits] = useState(0);
  const [numberOfCandidates, setNumberOfCandidates] = useState(isBulkUpload ? bulkCandidates : 1);
  const [showConfirmationTdsModal, setShowConfirmationTdsModal] = useState(false);
  const [tdsValue, setTdsValue] = useState('NONE');
  const [tdsCost, setTdsCost] = useState(0);

  const profile = useSelector((state) => state.profile);

  const availableBalance = candidateConfig.credits
    ? candidateConfig.credits.paymentsCompanyCreditBalance.creditBalance || 0
    : 0;

  // TDS config for this company selected fro SA side
  const tdsFromSAConfig = props?.paymentDetails?.tds || null;

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(step3Values, candidateConfig) * numberOfCandidates);
  }, [props?.step3Values]);

  const getTax = () => {
    return props?.paymentDetails?.tax / 100;
  };
  useEffect(() => {
    /* Adding (step3Values && totalPrice > 0) condition to start RazorPay flow if only if any addons are present
     else user are allowed to add candidate*/
    if (packageType === 'PREPAID' && step3Values && totalPrice > 0) {
      let taxAmount, insuffCreditsAmount, toPayAmount, taxCost, insuffAmount;
      insuffAmount = Math.abs(props?.insuffBalance);
      taxCost = getTax();
      if (taxCost > 0) {
        taxAmount = taxCost * Math.abs((totalPrice - availableBalance + insuffAmount).toFixed(2));
      }
      insuffCreditsAmount = totalPrice - availableBalance + insuffAmount;
      let tdsToPay = calculateTDS(insuffCreditsAmount, tdsValue === 'NONE' ? 0 : tdsValue);
      toPayAmount =
        totalPrice -
        availableBalance +
        insuffAmount +
        taxCost * (totalPrice - availableBalance + insuffAmount).toFixed(2);
      setTdsCost(tdsToPay);
      setTax(taxAmount);
      setInsuffCredits(insuffCreditsAmount);
      setPayableAmount(toPayAmount);
    } else {
      setPayableAmount(totalPrice);
    }
  }, [totalPrice]);

  useEffect(() => {
    setTdsCost(calculateTDS(insuffCredits, tdsValue === 'NONE' ? 0 : tdsValue));
  }, [tdsValue]);

  const isValidForm = () => {
    return (
      // if count > 0, then type and method, both should have present.
      // if both are present and type is specific then one config of (10th,12th...) must be selected

      (step3Values?.education?.count > 0
        ? step3Values?.education?.type && step3Values?.education?.method
          ? step3Values?.education?.type === 'SPECIFIC'
            ? step3Values?.education?.count === step3Values?.education?.config?.length
              ? true
              : false
            : true
          : false
        : true) &&
      (step3Values.address.config && step3Values.address.config.length > 0
        ? step3Values.address.config.every((obj) => {
          return Object.values(obj).every((value) => value);
        })
        : true) &&
      (step3Values.court.config && step3Values.court.config.length > 0
        ? step3Values.court.config.every((obj) => {
          return Object.values(obj).every((value) => value);
        })
        : true)
    );
  };

  const isAdmin = localStorage.accessLevel === 'admin';
  const getBulkUploadLabel = (price) => {
    if (numberOfCandidates > 1 && price) {
      return (
        <>
          {' '}
          ({numberOfCandidates} * <span className={styles.robotoFont}>₹</span>
          {maskPriceForAdmins(price, profile?.profile?.access_level, packageType)})
        </>
      );
    } else {
      return '';
    }
  };

  const getRazorpayPaymentObject = (buttonText = '', buttonIcon = '') => {
    return {
      createOrder: buyOrder,
      createOrderPayload: {
        credits: insuffCredits,
        use_current_wallet_balance: props?.paymentDetails.credits > 0 ? true : false,
        tds_percentage: tdsValue === 'NONE' ? 0 : parseInt(tdsValue),
        packages: []
      },
      payableAmount: insuffCredits.toFixed(2),
      buttonText: buttonText,
      setPaymentStatus: props?.setPaymentStatus,
      buttonIcon: buttonIcon,
      type: loggly_payment_type.CREATE_CANDIDATE
    };
  };

  const showAddons = () => {
    return (
      step3Values &&
      (totalPrice > 0 || step3Values.address.config.length || step3Values.court.config.length)
    );
  };

  return (
    <>
      <div className={styles.summaryContainer}>
        <h1 className={styles.summaryHeadingText}>{'Summary'}</h1>
        {step1Values ? (
          <div className={styles.candidateDetailsSection}>
            <p className={styles.heading}>{'CANDIDATE'}</p>
            <p className={styles.headingInfo}>{step1Values.name}</p>
            <p className={styles.headingDescription}>{step1Values.email}</p>
          </div>
        ) : null}
        {isBulkUpload ? (
          <div className={styles.candidateDetailsSection}>
            <p className={styles.heading}>{'CANDIDATES'}</p>
            <p className={styles.headingInfo}>{bulkCandidates}</p>
          </div>
        ) : null}
        {step2Values ? (
          <div className={styles.candidateDetailsSection}>
            <div className={styles.summaryChecksContainer}>
              <div>
                <p className={styles.heading}>{'PACKAGE'}</p>
                <p className={styles.headingInfo}>{`${isBulkUpload ? bulkCandidates : '1'} × ${step2Values.subtypeName === 'credit check'
                  ? 'Credit Check'
                  : step2Values.subtypeName
                  }`}</p>
              </div>
              {packageType === 'POSTPAID' && (
                <span className={styles.priceText}>
                  &#8377;
                  {maskPriceForAdmins(
                    step2Values.costPerCandidate * numberOfCandidates,
                    profile?.profile?.access_level,
                    packageType
                  )}
                </span>
              )}
            </div>
          </div>
        ) : null}
        {/* Addon summary */}
        <hr className={styles.divider} />
        {showAddons() ? (
          <div>
            <p style={{ marginBottom: '16px' }} className={styles.heading}>
              {'ADD-ONS'}
            </p>

            {step3Values.identity &&
              (step3Values.identity.any || step3Values.identity.config.length) ? (
              <div>
                <p className={styles.headingInfo} style={{ marginBottom: '16px' }}>
                  {'Identity'}
                </p>

                {step3Values.identity.any ? (
                  <div className={styles.summaryChecksContainer} style={{ marginBottom: '16px' }}>
                    <span className={styles.priceTextCheck}>
                      {`${step3Values.identity.any} * Any ID`}
                      {getBulkUploadLabel(candidateConfig.addons.identity.price)}
                    </span>

                    <span className={styles.priceText}>
                      &#8377;
                      {`${maskPriceForAdmins(
                        candidateConfig.addons.identity.price *
                        step3Values.identity.any *
                        numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}`}
                    </span>
                  </div>
                ) : null}

                {step3Values.identity.config && step3Values.identity.config.length
                  ? step3Values.identity.config.map((item, index) => {
                    return (
                      <div
                        className={styles.summaryChecksContainer}
                        style={{ marginBottom: '16px' }}
                        key={index}
                      >
                        <span className={styles.priceTextCheck}>
                          {`${item === 'VOTER'
                            ? `${IdentityArr['VOTER_ID']}`
                            : `${IdentityArr[item]}`
                            }`}
                          {getBulkUploadLabel(candidateConfig.addons.identity.price)}
                        </span>
                        <span className={styles.priceText}>
                          &#8377;
                          {maskPriceForAdmins(
                            candidateConfig.addons.identity.price * numberOfCandidates,
                            profile?.profile?.access_level,
                            packageType
                          )}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </div>
            ) : null}
            {step3Values.address &&
              (step3Values.address.any || step3Values.address.config.length) ? (
              <div>
                <p className={styles.headingInfo} style={{ marginBottom: '16px' }}>
                  {'Address'}
                </p>

                {/* {step3Values.address.any ? (
                  <div className={styles.summaryChecksContainer} style={{ marginBottom: '16px' }}>
                    <span className={styles.priceTextCheck}>
                      {`${step3Values.address.any} * Other address`}{' '}
                      {getBulkUploadLabel(candidateConfig.addons.address.price)}
                    </span>
                    <span className={styles.priceText}>
                      &#8377;
                      {convertToIndianNumeration(
                        candidateConfig.addons.address.price *
                          step3Values.address.any *
                          numberOfCandidates
                      )}
                    </span>
                  </div>
                ) : null} */}

                {step3Values.address.config && step3Values.address.config.length
                  ? step3Values.address.config.map((item, index) => {
                    return (
                      <div
                        className={styles.summaryChecksContainer}
                        style={{ marginBottom: '16px' }}
                        key={index}
                      >
                        <span className={styles.priceTextCheck}>
                          {`${AddressArr[item?.type]}`}

                          {item?.verificationType &&
                            getBulkUploadLabel(
                              getAddonPrice(
                                item?.verificationType?.replace(/_/g, ''),
                                1,
                                candidateConfig.addons
                              )
                            )}
                        </span>
                        {item?.verificationType && (
                          <span className={styles.priceText}>
                            &#8377;
                            {maskPriceForAdmins(
                              getAddonPrice(
                                item?.verificationType?.replace(/_/g, ''),
                                numberOfCandidates,
                                candidateConfig.addons
                              ),
                              profile?.profile?.access_level,
                              packageType
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })
                  : null}
              </div>
            ) : null}

            {step3Values.court && (step3Values.court.any || step3Values.court.config.length) ? (
              <div>
                <p className={styles.headingInfo} style={{ marginBottom: '16px' }}>
                  {'Court record'}
                </p>

                {/* {step3Values.court.any ? (
                  <div className={styles.summaryChecksContainer} style={{ marginBottom: '16px' }}>
                    <span className={styles.priceTextCheck}>
                      {`${step3Values.court.any} * Other address`}
                      {getBulkUploadLabel(candidateConfig.addons.court.price)}
                    </span>
                    <span className={styles.priceText}>
                      &#8377;
                      {convertToIndianNumeration(
                        candidateConfig.addons.court.price *
                          step3Values.court.any *
                          numberOfCandidates
                      )}
                    </span>
                  </div>
                ) : null} */}

                {step3Values.court.config && step3Values.court.config.length
                  ? step3Values.court.config.map((item, index) => {
                    return (
                      <div
                        className={styles.summaryChecksContainer}
                        key={index}
                        style={{ marginBottom: '16px' }}
                      >
                        <span className={styles.priceTextCheck}>
                          {`${AddressArr[item?.type]}`}{' '}
                          {item?.verificationType &&
                            getBulkUploadLabel(
                              getAddonPrice(
                                item?.verificationType?.replace(/_/g, ''),
                                1,
                                candidateConfig.addons
                              )
                            )}
                        </span>
                        {item?.verificationType && (
                          <span className={styles.priceText}>
                            &#8377;
                            {maskPriceForAdmins(
                              getAddonPrice(
                                item?.verificationType?.replace(/_/g, ''),
                                numberOfCandidates,
                                candidateConfig.addons
                              ),
                              profile?.profile?.access_level,
                              packageType
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })
                  : null}
              </div>
            ) : null}

            {step3Values.educationOfficial &&
              (step3Values.educationOfficial.count || step3Values.educationOfficial.config.length) ? (
              <>
                <div className={styles.summaryChecksContainer}>
                  <p className={styles.headingInfo}>
                    {`${!step3Values.educationOfficial.config.length
                      ? `${step3Values.educationOfficial.count} * `
                      : ''
                      }Education`}
                    {getBulkUploadLabel(candidateConfig.addons.education.price)}
                  </p>

                  {!step3Values.educationOfficial.config.length ? (
                    <span className={styles.priceText}>
                      &#8377;
                      {maskPriceForAdmins(
                        candidateConfig.addons.education.price *
                        step3Values.educationOfficial.count *
                        numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}
                    </span>
                  ) : null}
                </div>

                {step3Values.educationOfficial.config.length
                  ? step3Values.educationOfficial.config.map((item, index) => {
                    return (
                      <div className={styles.summaryChecksContainer} mb={2} key={index}>
                        <span className={styles.priceTextCheck}>
                          {`${EducationArr[item]}`}{' '}
                          {getBulkUploadLabel(candidateConfig.addons.education.price)}
                        </span>
                        <span className={styles.priceText}>
                          &#8377;
                          {maskPriceForAdmins(
                            candidateConfig.addons.education.price * numberOfCandidates,
                            profile?.profile?.access_level,
                            packageType
                          )}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </>
            ) : null}

            {step3Values.educationHybrid &&
              (step3Values.educationHybrid.count || step3Values.educationHybrid.config.length) ? (
              <>
                <div className={styles.summaryChecksContainer}>
                  <p className={styles.headingInfo}>
                    {`${!step3Values.educationHybrid.config.length
                      ? `${step3Values.educationHybrid.count} * `
                      : ''
                      }Education`}
                    {getBulkUploadLabel(candidateConfig.addons.educationHybrid.price)}
                  </p>

                  {!step3Values.educationHybrid.config.length ? (
                    <span className={styles.priceText}>
                      &#8377;
                      {maskPriceForAdmins(
                        candidateConfig.addons.educationHybrid.price *
                        step3Values.educationHybrid.count *
                        numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}
                    </span>
                  ) : null}
                </div>

                {step3Values.educationHybrid.config.length
                  ? step3Values.educationHybrid.config.map((item, index) => {
                    return (
                      <div className={styles.summaryChecksContainer} mb={2} key={index}>
                        <span className={styles.priceTextCheck}>
                          {`${EducationArr[item]}`}{' '}
                          {getBulkUploadLabel(candidateConfig.addons.educationHybrid.price)}
                        </span>
                        <span className={styles.priceText}>
                          &#8377;
                          {maskPriceForAdmins(
                            candidateConfig.addons.educationHybrid.price * numberOfCandidates,
                            profile?.profile?.access_level,
                            packageType
                          )}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </>
            ) : null}

            {step3Values.educationRegionalPartner &&
              (step3Values.educationRegionalPartner.count ||
                step3Values.educationRegionalPartner.config.length) ? (
              <>
                <div className={styles.summaryChecksContainer}>
                  <p className={styles.headingInfo}>
                    {`${!step3Values.educationRegionalPartner.config.length
                      ? `${step3Values.educationRegionalPartner.count} * `
                      : ''
                      }Education`}
                    {getBulkUploadLabel(candidateConfig.addons.educationThirdParty.price)}
                  </p>

                  {!step3Values.educationRegionalPartner.config.length ? (
                    <span className={styles.priceText}>
                      &#8377;
                      {maskPriceForAdmins(
                        candidateConfig.addons.educationThirdParty.price *
                        step3Values.educationRegionalPartner.count *
                        numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}
                    </span>
                  ) : null}
                </div>

                {step3Values.educationRegionalPartner.config.length
                  ? step3Values.educationRegionalPartner.config.map((item, index) => {
                    return (
                      <div className={styles.summaryChecksContainer} mb={2} key={index}>
                        <span className={styles.priceTextCheck}>
                          {`${EducationArr[item]}`}
                          {getBulkUploadLabel(candidateConfig.addons.educationThirdParty.price)}
                        </span>
                        <span className={styles.priceText}>
                          &#8377;
                          {maskPriceForAdmins(
                            candidateConfig.addons.educationThirdParty.price * numberOfCandidates,
                            profile?.profile?.access_level,
                            packageType
                          )}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </>
            ) : null}
            {step3Values.education &&
              (step3Values.education.count || step3Values.education.config.length) ? (
              <>
                <div className={styles.summaryChecksContainer}>
                  <p className={styles.headingInfo}>
                    {`${!step3Values.education.config.length
                      ? `${step3Values.education.count} * `
                      : ''
                      }Education `}
                    {step3Values.education.type === 'CHRONOLOGICAL' && (
                      <span className={styles.priceText}>
                        {getBulkUploadLabel(getEduPrice(step3Values, candidateConfig))}
                      </span>
                    )}
                  </p>

                  {!step3Values.education.config.length ? (
                    <span className={styles.priceText}>
                      &#8377;
                      {maskPriceForAdmins(
                        getEduPrice(step3Values, candidateConfig) *
                        step3Values.education.count *
                        numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}
                    </span>
                  ) : null}
                </div>

                {step3Values.education.config.length
                  ? step3Values.education.config.map((item, index) => {
                    return (
                      <div className={styles.summaryChecksContainer} mb={2} key={index}>
                        <span className={styles.priceTextCheck}>
                          {`${EducationArr[item]}`}
                          <span className={styles.priceText}>
                            {getBulkUploadLabel(getEduPrice(step3Values, candidateConfig))}
                          </span>
                        </span>
                        <span className={styles.priceText}>
                          &#8377;
                          {maskPriceForAdmins(
                            getEduPrice(step3Values, candidateConfig) * numberOfCandidates,
                            profile?.profile?.access_level,
                            packageType
                          )}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </>
            ) : null}

            {step3Values.employment ? (
              <div className={styles.summaryChecksContainer}>
                <p className={styles.headingInfo}>
                  {`${step3Values.employment} * Employment`}
                  <span className={styles.priceText}>
                    {getBulkUploadLabel(candidateConfig.addons.employment.price)}
                  </span>
                </p>
                <span className={styles.priceText}>
                  &#8377;
                  {maskPriceForAdmins(
                    candidateConfig.addons.employment.price *
                    step3Values.employment *
                    numberOfCandidates,
                    profile?.profile?.access_level,
                    packageType
                  )}
                </span>
              </div>
            ) : null}

            {step3Values.reference &&
              step3Values.reference.config &&
              step3Values.reference.config.length &&
              getCount(step3Values.reference.config) ? (
              <>
                <div className={styles.summaryChecksContainer} style={{ marginBottom: 0 }}>
                  <p className={styles.headingInfo}>{`Reference`}</p>
                </div>
                {step3Values.reference.config.map((item, index) => {
                  return parseInt(item) > 0 ? (
                    <div className={styles.summaryChecksContainer} key={index}>
                      <span className={styles.priceTextCheck}>
                        {`${item} * ${index === 0 ? `Last employment ` : `${ordinal(index + 1)} Last employment`
                          } `}
                        {getBulkUploadLabel(candidateConfig.addons.reference.price)}
                      </span>
                      <span className={styles.priceText}>
                        &#8377;
                        {maskPriceForAdmins(
                          candidateConfig.addons.reference.price *
                          parseInt(item) *
                          numberOfCandidates,
                          profile?.profile?.access_level,
                          packageType
                        )}
                      </span>
                    </div>
                  ) : null;
                })}
              </>
            ) : null}

            {step3Values.world && step3Values.world ? (
              <div className={styles.summaryChecksContainer}>
                <p className={styles.headingInfo}>
                  {`World check`}
                  <span className={styles.priceTextCheck}>
                    {getBulkUploadLabel(candidateConfig.addons.world.price)}
                  </span>
                </p>
                {candidateConfig && candidateConfig.addons && candidateConfig.addons.world ? (
                  <span className={styles.priceText}>
                    &#8377;
                    {maskPriceForAdmins(
                      candidateConfig.addons.world.price * numberOfCandidates,
                      profile?.profile?.access_level,
                      packageType
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}

            {step3Values.drug5Panel ? (
              <div className={styles.summaryChecksContainer}>
                <p component={'p'} className={styles.headingInfo}>
                  {`Drug test (5 Panel)`}
                  <span className={styles.priceTextCheck}>
                    {getBulkUploadLabel(candidateConfig.addons.drug5Panel.price)}
                  </span>
                </p>
                {candidateConfig && candidateConfig.addons && candidateConfig.addons.drug5Panel ? (
                  <span component={'span'} className={styles.priceText}>
                    &#8377;
                    {maskPriceForAdmins(
                      candidateConfig.addons.drug5Panel.price * numberOfCandidates,
                      profile?.profile?.access_level,
                      packageType
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}
            {step3Values.drug7Panel ? (
              <div className={styles.summaryChecksContainer}>
                <p component={'p'} className={styles.headingInfo}>
                  {`Drug test (7 Panel)`}
                  <span className={styles.priceTextCheck}>
                    {getBulkUploadLabel(candidateConfig.addons.drug7Panel.price)}
                  </span>
                </p>
                {candidateConfig && candidateConfig.addons && candidateConfig.addons.drug7Panel ? (
                  <span component={'span'} className={styles.priceText}>
                    &#8377;
                    {maskPriceForAdmins(
                      candidateConfig.addons.drug7Panel.price * numberOfCandidates,
                      profile?.profile?.access_level,
                      packageType
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}
            {step3Values.drug10Panel ? (
              <div className={styles.summaryChecksContainer}>
                <p component={'p'} className={styles.headingInfo}>
                  {`Drug test (10 Panel)`}
                  <span className={styles.priceTextCheck}>
                    {getBulkUploadLabel(candidateConfig.addons.drug10Panel.price)}
                  </span>
                </p>
                {candidateConfig && candidateConfig.addons && candidateConfig.addons.drug10Panel ? (
                  <span component={'span'} className={styles.priceText}>
                    &#8377;
                    {maskPriceForAdmins(
                      candidateConfig.addons.drug10Panel.price * numberOfCandidates,
                      profile?.profile?.access_level,
                      packageType
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}

            {step3Values.cibil && step3Values.cibil ? (
              <div className={styles.summaryChecksContainer}>
                <span className={styles.headingInfo}>
                  {`Credit Check`}
                  <span className={styles.priceTextCheck}>
                    {getBulkUploadLabel(candidateConfig.addons.cibil.price)}
                  </span>
                </span>

                {candidateConfig && candidateConfig.addons && candidateConfig.addons.cibil ? (
                  <span className={styles.priceText}>
                    &#8377;
                    {maskPriceForAdmins(
                      candidateConfig.addons.cibil.price * numberOfCandidates,
                      profile?.profile?.access_level,
                      packageType
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}
            {step3Values.socialMedia.config.length ? (
              <div className={styles.socialMediaContainerStyles}>
                <div className={styles.summaryChecksContainer} marginBottom={'10px'}>
                  <p component={'p'} className={styles.headingInfo}>
                    Social Media Check
                    <span className={styles.priceTextCheck}>
                      {getBulkUploadLabel(candidateConfig.addons.socialMedia.price)}
                    </span>
                  </p>
                  {candidateConfig?.addons?.socialMedia && (
                    <span component={'span'} className={styles.priceText}>
                      &#8377;
                      {maskPriceForAdmins(
                        candidateConfig.addons.socialMedia.price * numberOfCandidates,
                        profile?.profile?.access_level,
                        packageType
                      )}
                    </span>
                  )}
                </div>
                {step3Values?.socialMedia?.config?.length &&
                  step3Values.socialMedia.config.map((item, index) => {
                    return (
                      <div className={styles.socialPriceTextCheck} key={index}>
                        {SocialMediaConstants[item]}
                      </div>
                    );
                  })}
              </div>
            ) : null}
            <hr className={styles.divider} />
          </div>
        ) : null}
        {packageType === 'PREPAID' && step3Values && totalPrice > 0 && availableBalance > 0 ? (
          <div className={styles.amountDetailsContainer}>
            <p className={styles.creditBalanceText}>{'Available Credit Balance'}</p>
            <p className={styles.creditBalancePriceText}>
              &#8377;
              {convertToIndianNumeration(Math.abs(availableBalance))}
            </p>
          </div>
        ) : null}
        {packageType === 'PREPAID' &&
          step3Values &&
          totalPrice > 0 &&
          (props?.insuffBalance || availableBalance < 0) ? (
          <div className={styles.amountDetailsContainer}>
            <p className={styles.creditBalanceText}>{'Arrears'}</p>
            <p className={styles.creditBalancePriceText}>
              &#8377;
              {convertToIndianNumeration(
                Math.abs(props?.insuffBalance) +
                Math.abs(availableBalance < 0 ? availableBalance : 0)
              )}
            </p>
          </div>
        ) : null}
        {insuffCredits > 0 && step3Values && totalPrice > 0 ? (
          <>
            <div className={styles.amountDetailsContainer}>
              <p className={styles.creditBalanceText}>{'Total'}</p>
              <p className={styles.insuffBalancePriceText}>
                <span className={styles.fontFamilyRoboto}>&#8377;</span>
                {maskPriceForAdmins(insuffCredits, profile?.profile?.access_level, packageType)}
              </p>
            </div>
          </>
        ) : null}
        {packageType === 'PREPAID' &&
          (insuffCredits > 0 || props.insuffBalance) &&
          step3Values &&
          totalPrice > 0 ? (
          <>
            <div className={styles.amountDetailsContainer}>
              <p className={styles.taxText}>{`Tax (${props?.paymentDetails?.tax}%)`}</p>
              <p className={styles.taxPrice}>
                <span className={styles.fontFamilyRoboto}>&#8377;</span>
                {convertToIndianNumeration(tax)}
              </p>
            </div>
            {/* render TDS options only if 2% or 10% is selected from SA side for that company not for NONE selection*/}
            {tdsFromSAConfig != null && tdsFromSAConfig != '' && tdsFromSAConfig !== 'NONE' && (
              <div className={styles.amountDetailsContainer} style={{ marginLeft: '-18px' }}>
                <p className={styles.taxText}>
                  <CompanyTds
                    setTdsValue={(val) => {
                      setTdsValue(val);
                      props?.setFieldValue(val);
                    }}
                    tdsCost={convertToIndianNumeration(tdsCost)}
                    savedTdsValue={props?.step3Values?.tdsValue}
                  />
                </p>
              </div>
            )}
          </>
        ) : null}
        {packageType === 'POSTPAID' ? (
          <>
            <div className={styles.amountDetailsContainer}>
              <p className={styles.creditBalanceText}>Total</p>
              <p className={styles.creditBalanceText}>
                <span className={styles.fontFamilyRoboto}>&#8377;</span>{' '}
                {maskPriceForAdmins(
                  payableAmount + costPerCandidate * numberOfCandidates,
                  profile?.profile?.access_level,
                  packageType
                )}
              </p>
            </div>
          </>
        ) : null}
        <div className={styles.addBtnContainer}>
          {payableAmount <= 0 || packageType === 'POSTPAID' ? (
            <Button
              className={props?.isSubmitting ? styles.payDisableBtn : styles.payBtn}
              disabled={props?.isSubmitting || !isValidForm()}
              onClick={() => props?.handleStepSubmit(['step3'], [props?.step3Values], 'submit')}
            >
              {props?.isSubmitting ? (
                <Spinner animation='border' variant='light' size='sm' />
              ) : (
                'Add candidate'
              )}
            </Button>
          ) : tdsCost > 0 ? (
            <Button
              className={styles.payBtn}
              onClick={() => setShowConfirmationTdsModal(!showConfirmationTdsModal)}
            >
              <Image name='lock_guard_white.svg' />
              &nbsp;
              <span>PAY NOW</span>
            </Button>
          ) : (
            <RazorpayButton
              setShowLoader={setShowLoader}
              {...getRazorpayPaymentObject('PAY NOW', 'lock_guard_white.svg')}
              disable={!isValidForm()}
            />
          )}
        </div>
      </div>

      {payableAmount > 0 && packageType === 'PREPAID' && (
        <div className={styles.infoWarningContainer}>
          <Alert variant={'warning'} className={styles.alert}>
            <span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={'#FAAD14'}
                style={{ marginRight: '8px' }}
              />
            </span>
            {isAdmin ? (
              <p>{'Insufficient credits for add-ons. Please add credits to purchase add-ons.'}</p>
            ) : (
              <p>
                {
                  'Insufficient credits for add-ons. Please ask the admin to purchase more credits for your company.'
                }
              </p>
            )}
          </Alert>
        </div>
      )}

      {candidateConfig.isCreditLimitExceeded == true && packageType === 'POSTPAID' ? (
        <div className={styles.infoWarningContainer}>
          <Alert variant={'warning'} className={styles.alert}>
            <span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={'#FAAD14'}
                style={{ marginRight: '8px' }}
              />
            </span>
            {candidateConfig.billingType && candidateConfig.billingType == 'CHECK_INITIATED' ? (
              <p>
                {
                  'Verification process might not begin for this candidate as you have exceeded maximum outstanding balance'
                }
              </p>
            ) : candidateConfig.billingType ? (
              <p>
                {
                  'Verification process might not complete for this candidate as you have exceeded maximum outstanding balance'
                }
              </p>
            ) : (
              ''
            )}
          </Alert>
        </div>
      ) : null}
      {packageType === 'POSTPAID' ? (
        <div className={styles.infoWarningContainer}>
          <Alert variant={'primary'} className={styles.alert}>
            <span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={'#1890FF'}
                style={{ marginRight: '8px' }}
              />
            </span>

            <p>{'Total amount will be added to monthly invoice.'}</p>
          </Alert>
        </div>
      ) : null}
      {showConfirmationTdsModal && (
        <ConfirmationTdsModal
          setShowLoader={setShowLoader}
          show={showConfirmationTdsModal}
          onClose={() => setShowConfirmationTdsModal(!showConfirmationTdsModal)}
          paymentsObj={getRazorpayPaymentObject('Confirm purchase')}
        />
      )}
    </>
  );
}

export default Summary;
