import React, { useEffect, useState } from 'react';

import styles from '../../Integrations.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import Select from 'react-select';

import TopTextArea from '../../Utils/TopTextArea';
import IntegrationLayout from '../../Utils/Layout';
import Button from '../../../core-components/Button';
import WarningModal from '../../WarningModal';
import ConfirmationModal from '../../../core-components/ConfirmationModal';
import Image from '../../../core-components/Image';
import _, { isEmpty } from 'lodash';

import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/Utilities';
import {
  getReputeLocations,
  getReputeJobRoles,
  getReputeGroups,
  getTRUTOHRMSGroups,
  getTRUTOHRMSLocations,
  getTRUTOHRMSJobRoles,
  getViewBuyPackages
} from '../../../../../../../src/api/company';
import {
  deleteIntegrations,
  getIntegrationFieldSubtypeMapping
} from '../../../../../../api/company';

export default function Repute(props) {
  const { setFieldValue, values, handleSubmit } = props;
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [identifierValues, setIdentifierValues] = useState([]);
  const [selectedIdentifierValues, setSelectedIdentifierValues] = useState({});
  const [dropdownIdentifierValues, setDropdownIdentifierValues] = useState([]);
  const [identifierDataLoading, setIdentifierDataLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [reputeSubtypeMapping, setReputeSubtypeMapping] = useState([]);
  const [isPackageMapped, setIsPackageMapped] = useState(false);

  const reputeIdentifierArray = ['Job Roles', 'Locations', 'Groups'];
  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    if (integrationData.repute?.token) {
      let subtypeMapping = {};
      getIntegrationFieldSubtypeMapping({
        companyId: props.values.companyId,
        integrationName: 'REPUTE'
      })
        .then((res) => {
          if (res.data.length > 0) {
            setIsPackageMapped(true);
          }
          res.data.map((mapping) => {
            if (subtypeMapping?.hasOwnProperty(mapping.subtype_id_fk)) {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: [
                  ...subtypeMapping[mapping.subtype_id_fk].tags,
                  ...mapping.integration_field_value.split(';')
                ]
              };
            } else {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: mapping.integration_field_value.split(';')
              };
            }
          });
          setReputeSubtypeMapping(subtypeMapping);
        })
        .catch((err) => console.error(err));
    }
  }, [props.integrationData]);

  useEffect(() => {
    setFieldValue('tags', reputeSubtypeMapping);
  }, [reputeSubtypeMapping]);

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);
  }, [props?.integrationData]);

  useEffect(() => {
    setFieldValue('companyId', props.profile.profile.company_user_mapping.company_id);
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const backHandler = () => {
    props.history.push('/integrations');
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('REPUTE')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        setIsOpen(false);
        backHandler();
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  useEffect(() => {
    getViewBuyPackages({ limit: 50, offset: 0 })
      .then((result) => {
        setPackages(result.data.data.rows);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  }, []);

  const mapAPICallToIdentifier = async () => {
    setIdentifierDataLoading(true);
    const { identifierValue, companyId } = props.values;
    const integration_id = props.integrationData?.repute?.integrationId;
    const identifierFormattedData = [];
    if (identifierValue === 'Job Roles') {
      let resp;
      if (integration_id) {
        resp = await getTRUTOHRMSJobRoles({ integration_id });
      } else {
        resp = await getReputeJobRoles(companyId);
      }
      setIdentifierDataLoading(false);
      resp?.data?.result?.forEach((item) => {
        identifierFormattedData.push(item.name);
      });
    } else if (identifierValue === 'Locations') {
      let resp;
      if (integration_id) {
        resp = await getTRUTOHRMSLocations({ integration_id });
      } else {
        resp = await getReputeLocations(companyId);
      }
      setIdentifierDataLoading(false);
      resp?.data?.result?.forEach((item) => {
        identifierFormattedData.push(item.name);
      });
    } else if (identifierValue === 'Groups') {
      let resp;
      if (integration_id) {
        resp = await getTRUTOHRMSGroups({ integration_id });
      } else {
        resp = await getReputeGroups(companyId);
      }
      setIdentifierDataLoading(false);
      resp?.data?.result?.forEach((item) => {
        identifierFormattedData.push(item.name);
      });
    }
    const uniqueIdentifierData = Array.from(new Set(identifierFormattedData));
    setIdentifierValues(uniqueIdentifierData);
  };

  useEffect(() => {
    if (props.integrationData?.repute?.token) {
      mapAPICallToIdentifier();
    }
  }, [props.values.identifierValue]);

  const tagDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#666666',
      borderRadius: '4px',
      padding: '10px 20px',
      border: 'none',
      background: state.isFocused ? '#ffffff' : ''
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ebedf2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      display: 'flex'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#333333'
      };
    },
    valueContainer: (base) => ({
      ...base
    }),
    container: (base) => ({
      ...base
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '10'
    })
  };

  const onClickIdentifierHandler = () => {
    mapAPICallToIdentifier();
  };

  const setTagsValue = (packageId, subtypeId, value) => {
    let allSubtypesWithTags = { ...reputeSubtypeMapping, ...selectedIdentifierValues };

    allSubtypesWithTags[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};

    setSelectedIdentifierValues(allSubtypesWithTags);
    setFieldValue('tags', allSubtypesWithTags);
  };

  useEffect(() => {
    const selectedTagsObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj.tags);
    setDropdownIdentifierValues(_.difference(identifierValues, _.union(...allTagsArr)));
  }, [selectedIdentifierValues, identifierValues]);

  const checkIfTagsNotAvailable = () => {
    const selectedIdentifierValuesObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedIdentifierValuesObj).map(
      (obj) => !isEmpty(obj) && obj?.tags
    );
    return _.difference(_.union(...allTagsArr), identifierValues).length > 0;
  };

  const checkForTagsError = (tags) => {
    if (tags?.length > 0 && !identifierValues.includes(...tags) && !identifierDataLoading) {
      return <span className={styles.errorInline}>Tags not available</span>;
    }
    return null;
  };
  const identifierSeparator = ({ innerProps }) => {
    return <span style={{ display: 'none' }} {...innerProps} />;
  };

  const reputeDetailsAPIKey = [
    `Login to ${props.integrationData?.repute?.hrmsName || 'Repute'} application`,
    'Go to Market place and Select SpringVerify Application',
    'Enable SpringVerify Application'
  ];

  const isFormValid = () => {
    return (
      !isEmpty(values.identifierValue) &&
      Object.values(values?.tags).filter((tag) => tag && !isEmpty(tag)).length === packages.length
    );
  };
  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea
            title={props.integrationData?.repute?.hrmsName || 'Repute'}
            icon={props.integrationData?.repute?.hrmsLogoUrl || 'repute.png'}
            showConnected={integrationData?.repute?.token}
            isIconUrl={props.integrationData?.repute?.hrmsLogoUrl}
          />
          <Container>
            {!integrationData?.repute?.token ? (
              <div className='mt-5'>
                <Row>
                  <h6 className='font-weight-bold'>
                    To Integrate SpringVerify with{' '}
                    {props.integrationData?.repute?.hrmsName || 'Repute'}
                  </h6>
                </Row>
                {reputeDetailsAPIKey.map((step, index) => {
                  return (
                    <Row>
                      <h6>
                        <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                      </h6>
                    </Row>
                  );
                })}
              </div>
            ) : (
              <div>
                <div className={styles.settingPageContainer}>
                  <Row className='justify-content-between'>
                    <span className={styles.settingPageHeaderText}> Select Identifier</span>
                  </Row>
                  <div className={styles.settingPageHeaderSubText}>
                    Select {props.integrationData?.repute?.hrmsName} Identifier with which you want
                    to Map SpringVerify packages
                  </div>
                </div>

                <Row className={styles.dropdownContainer}>
                  <DropdownList
                    disabled={isPackageMapped}
                    style={{ width: '300px' }}
                    data={reputeIdentifierArray}
                    dataKey='id'
                    textField='text'
                    value={values?.identifierValue}
                    name='identifierValue'
                    selectIcon={<Image name='dropdownArrow.svg' />}
                    onChange={(value) => {
                      setFieldValue('identifierValue', value);
                    }}
                  />
                </Row>

                <div className={styles.secondPageSubSectionContainer}>
                  <Row>
                    <span className={styles.settingPageSubSectionHeader}>
                      {' '}
                      Setup Package Association
                    </span>
                  </Row>
                  <div className={styles.settingPageSubSectionList}>
                    Match the relevant {props.values.identifierValue} in{' '}
                    {props.integrationData?.repute?.hrmsName} to the packages on SpringVerify
                  </div>
                </div>

                <div className={styles.packageSelectorHeaderContainer} xs={2} md={4} lg={6}>
                  <Col md={3} className={styles.packageNameHeader}>
                    <div className={styles.packageTextStyle}>PACKAGE NAME</div>
                  </Col>
                  <Col className={styles.refreshPackageContainer}>
                    <div className={styles.packageTextStyle}>
                      SELECT {props.values.identifierValue.toUpperCase()}
                    </div>
                    <div
                      className={styles.refreshPackageButton}
                      onClick={() => onClickIdentifierHandler()}
                    >
                      <Image className={styles.refreshImageContainer} name='reset.png' />
                      <span className={styles.refreshText}>Refresh</span>
                    </div>
                  </Col>
                </div>
                {packages.map((packageRow) => (
                  <Row xs={2} md={4} lg={6} className='my-2'>
                    <Col md={3} className={styles.packageName}>
                      <div className={styles.text}>
                        <span className={styles.packageNameText}>{packageRow.package_name}</span>
                        <span className={styles.packageSubtypeNameText}>
                          {packageRow.subtype_name}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <Select
                        className={styles.tagsDropDown}
                        isMulti
                        placeholder={
                          identifierDataLoading
                            ? `${props.values.identifierValue} loading...`
                            : identifierValues.length > 0
                            ? `Please select ${props.values.identifierValue}`
                            : `No ${props.values.identifierValue}`
                        }
                        name='tags'
                        components={{ identifierSeparator }}
                        options={dropdownIdentifierValues}
                        getOptionLabel={(option) => option}
                        getOptionValue={(option) => option}
                        styles={tagDropdownStyles}
                        isClearable={false}
                        noOptionsMessage={() => (
                          <span className={styles.dropDownNoOptionMessage}>
                            No {props.values.identifierValue} available. Please create relevant{' '}
                            {props.values.identifierValue} on{' '}
                            {props.integrationData?.repute?.hrmsName} and refresh.
                          </span>
                        )}
                        value={values?.tags[packageRow.subtype_id]?.tags}
                        onChange={(e) => {
                          setTagsValue(packageRow.package_id, packageRow.subtype_id, e);
                        }}
                      />
                      {checkForTagsError(values?.tags[packageRow.subtype_id]?.tags)}
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Container>
        </div>
        <Container>
          <div className={styles.footerButtonContainer}>
            <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
              Go Back
            </Button>
            {integrationData?.repute?.token && (
              <div>
                <Button
                  className={styles.removeButtonRepute}
                  onClick={() => setIsOpen(true)}
                  variant='outline-danger'
                  disabled={!isPackageMapped && identifierDataLoading}
                >
                  Disconnect
                </Button>

                <Button
                  className={styles.activeBtn}
                  onClick={handleSubmit}
                  disabled={!isFormValid() || checkIfTagsNotAvailable()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </Container>
      </IntegrationLayout>

      {isOpen ? (
        <WarningModal
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
          }}
          title={props.integrationData?.repute?.hrmsName.toUpperCase()}
          onConfirm={() => {
            onRemoveConnectionHandler();
          }}
        />
      ) : null}

      {showModal ? (
        <ConfirmationModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          heading={'Integration Alert'}
          onSubmit={() => {
            setShowModal(false);
            props.history.push(`/integrations`);
          }}
          enableBtn={true}
          subHeading={
            'Your account includes multiple packages and requires a custom configuration. Our CS team has been alerted about your request and will get in touch with you to enable the integration.'
          }
          sBtnText={'Close'}
          hideCloseBtn={true}
        />
      ) : null}
    </>
  );
}
