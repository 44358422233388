import React, { useState, useEffect } from 'react';
import { isEmpty, has } from 'lodash';
import moment from 'moment';
import { Modal, Row, Col, Button, Form, Spinner, CloseButton, Badge } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';

import { RenderErrorUI, RenderUploadedFile } from './CommonUploadComponent';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import Image from '../../../../../../../core-components/Image';
import logger from '../../../../../../../core-components/Logger';
import {
  FileErrorMeaage,
  getFileHeader,
  toSnakeCase,
  validateImage
} from '../../../../../../../utils/utilities';
import {
  DEGREE_DROPDOWN,
  MARKSHEET_DROPDOWN,
  obj
} from '../../../../../../../utils/commonConstant';
import PasswordProtectedModal from '../../../PasswordProtectedModal';
import ModalWarningSvg from '../../../../../../../images/warning.svg';

import styles from './AddEditUpload.module.scss';
import { pdfjs } from 'react-pdf';
import cn from 'classnames';
import { validateDocument } from '../../../../../../../api/bgv';
import { Input } from '@material-ui/core';

const AddEditUpload = (props) => {
  const [docUpload, setDocUpload] = useState({
    certificate: { link: '', error: '', loading: false },
    gradeSheet: []
  });
  const [educationCheck, setEducationCheck] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [isDateCorrect, setIsDateCorrect] = useState(true);
  const [noGradeSheetCheckbox, setNoGradeSheetCheckbox] = useState(false);
  const [takeCertificatePhoto, setTakeCertificatePhoto] = useState(false);
  const [takeGradesheetPhoto, setTakeGradesheetPhoto] = useState(false);
  const [isCameraUploadError, setIsCameraUploadError] = useState(false);
  const [marksheetArr] = useState(['10', '12']);
  const [maxFileError, setMaxFileError] = useState(false);
  const [passwordProtectedFiles, setPasswordProtectedFiles] = useState([]);
  const [passwordProtectedUploadedLength, setPasswordProtectedUploadedLength] = useState(0); // Store initial password protected length
  const [bothCertificateMarksheet] = useState([
    'UNDERGRAD',
    'POSTGRAD',
    'PHD',
    '12/Diploma',
    'Other',
    'UG',
    'PG',
    'PHD',
    'Undergraduate',
    'Postgraduate',
    'Post Graduation',
    'MEDICAL REGISTRATION CERTIFICATE',
    'Medical Registration Certificate'
  ]);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [uploadedType, setUploadedType] = useState('');

  const [fileUploading, setFileUploading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [isValidatingDoc, setIsValidatingDoc] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherFieldValue, setOtherFieldValue] = useState('');

  useEffect(() => {
    handleInitialState();
  }, []);

  useEffect(() => {
    if (passwordProtectedFiles.length) {
      setShowPasswordProtectedModal(true);
    }
  }, [passwordProtectedFiles]);

  const validateDoc = async (url) => {
    // Set up form data for validation
    const formData = new FormData();
    formData.append('prompt_name', 'educationPromptID');
    formData.append('url', url);

    try {
      setIsValidatingDoc(true);
      const { data } = await validateDocument(formData);

      // Check if validation was successful
      if (data) {
        setIsValidatingDoc(false);
        return data?.aiResponse;
      } else {
        setIsValidatingDoc(false);
        console.error('Validation failed with response:', data);
        return null;
      }
    } catch (error) {
      setIsValidatingDoc(false);
      console.error('Error validating document:', error);
      return null;
    }
  };

  const handleCertificateUpload = async (formData, isPdf, file, resolve, isCam = false) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      if (reader.result) {
        try {
          // Upload the document first
          const res = await props.educationGradesheetUpload({ formData, isPdf });

          let docCopy = {
            link: res.link,
            error: '',
            loading: false,
            isPdf: isPdf
          };

          // Validate the uploaded document
          let validationResult;
          if (!docCopy.error && !isPdf) {
            validationResult = await validateDoc(res.link);
            docCopy = { ...docCopy, ...validationResult };
          }

          if (isPdf) {
            const loadingTask = pdfjs.getDocument({
              data: reader?.result,
              password: ''
            });

            try {
              await loadingTask.promise;
              if (!docCopy.error) {
                validationResult = await validateDoc(res.link);
                docCopy = { ...docCopy, ...validationResult };
              }

              // If validation is successful or not explicitly failed
              if (validationResult !== null || validationResult !== undefined) {
                resolve({
                  docCopy: { ...docCopy, ...validationResult }
                });
              } else {
                // Validation failed
                resolve({
                  docCopy: {
                    ...docCopy,
                    error: 'Document validation failed'
                  }
                });
              }
            } catch (error) {
              if (error.message === 'No password given') {
                resolve({
                  docCopy: {
                    ...docCopy,
                    fileName: file.name
                  },
                  isPasswordProtected: true
                });
              }
            }
          } else {
            if (!isCam) {
              // For non-PDF and non-camera uploads
              if (validationResult !== null) {
                resolve({
                  docCopy: { ...docCopy, ...validationResult }
                });
              } else {
                resolve({
                  docCopy: {
                    ...docCopy,
                    error: 'Document validation failed'
                  }
                });
              }
            } else {
              // Camera-specific handling
              handleCertificatePhoto();
              setDocUpload((prevDocUpload) => {
                return {
                  ...prevDocUpload,
                  certificate: {
                    ...updateDocCopyAtIndex({
                      link: res?.link,
                      error: false,
                      isPdf: false
                    })
                  }
                };
              });
            }
          }
        } catch (error) {
          // Upload failed
          let docCopy = {
            link: '',
            error: 'Upload failed. File format not valid.',
            loading: false,
            isPdf: isPdf,
            password: ''
          };
          resolve(docCopy);
        }
      }
    };
  };

  const handleGradesheetUpload = async (formData, isPdf, index, file, resolve, isCam = false) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      if (reader.result) {
        try {
          // Upload the document first
          const res = await props.educationGradesheetUpload({ formData, isPdf });

          let docCopy = {
            link: res.link,
            error: '',
            loading: false,
            isPdf: isPdf
          };

          // Validate the uploaded document
          let validationResult;
          if (!docCopy.error && !isPdf) {
            validationResult = await validateDoc(res.link);
            docCopy = { ...docCopy, ...validationResult };
          }

          if (isPdf) {
            const loadingTask = pdfjs.getDocument({
              data: reader?.result,
              password: ''
            });

            try {
              await loadingTask.promise;

              if (!docCopy.error) {
                validationResult = await validateDoc(res.link);
                docCopy = { ...docCopy, ...validationResult };
              }

              // If validation is successful or not explicitly failed
              if (validationResult !== null || validationResult !== undefined) {
                resolve({
                  docCopy: { ...docCopy, ...validationResult },
                  index
                });
              } else {
                // Validation failed
                resolve({
                  docCopy: {
                    ...docCopy,
                    error: 'Document validation failed'
                  },
                  index
                });
              }
            } catch (error) {
              if (error.message === 'No password given') {
                resolve({
                  docCopy: {
                    ...docCopy,
                    fileName: file.name
                  },
                  index,
                  isPasswordProtected: true
                });
              }
            }
          } else {
            if (!isCam) {
              // For non-PDF and non-camera uploads
              if (validationResult !== null) {
                resolve({
                  docCopy: { ...docCopy, ...validationResult },
                  index
                });
              } else {
                resolve({
                  docCopy: {
                    ...docCopy,
                    error: 'Document validation failed'
                  },
                  index
                });
              }
            } else {
              // Camera-specific handling
              handleGradesheetPhoto();
              setDocUpload((prevDocUpload) => {
                const docCopy = [...prevDocUpload.gradeSheet];
                docCopy.push({
                  link: res?.link,
                  error: false,
                  isPdf: false
                });
                return {
                  ...prevDocUpload,
                  gradeSheet: [...docCopy]
                };
              });
            }
          }
        } catch (error) {
          // Upload failed
          const docCopy = [];
          docCopy[index] = {
            link: '',
            error: 'Upload failed. File format not valid.',
            loading: false,
            isPdf: isPdf,
            password: ''
          };
          resolve(docCopy);
        }
      }
    };
  };

  /* Utility/common functions */
  const updateDocCopyAtIndex = ({
    link = '',
    error = null,
    loading = false,
    isPdf = true,
    password = null
  }) => {
    return {
      link,
      error,
      loading,
      isPdf,
      password
    };
  };

  const getErrMessage = (result, blob) => {
    let fileType;
    var arr = new Uint8Array(result).subarray(0, 4);
    var header = '';
    for (var i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    // Check the file signature against known types
    fileType = getFileHeader(header);
    const errMessage = validateImage(blob, fileType);
    const isPdf = fileType === 'application/pdf' ? true : false;
    return { errMessage, isPdf };
  };

  // if (type === 'certificate') {
  // } else if (type == 'gradesheet') {
  // }
  const handleFileSelect = (e, type, index) => {
    setFileUploading(true);
    setUploadedType(type);
    const numberOfFiles = docUpload.gradeSheet.length + e.target.files.length;
    if (type === 'gradesheet' && numberOfFiles > 10) {
      setMaxFileError(true);
      setFileUploading(false);
      return;
    }
    setMaxFileError(false);
    const promises = Array.from(e.target.files).map((file, ind) => {
      if (type === 'certificate') {
        setDocUpload((prevDocUpload) => {
          return {
            ...prevDocUpload,
            certificate: updateDocCopyAtIndex({ error: '', loading: true })
          };
        });
      } else if (type == 'gradesheet') {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.gradeSheet];
          docCopy[index] = updateDocCopyAtIndex({ error: '', loading: true });
          return {
            ...prevDocUpload,
            gradeSheet: docCopy
          };
        });
      }
      return new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onloadend = () => {
          const { errMessage, isPdf } = getErrMessage(fileReader.result, file);
          if (!errMessage) {
            let formData = new FormData();
            formData.append(`file`, file);
            formData.append(`file_type`, 'CHECK_DOCUMENT');
            if (type === 'certificate') {
              handleCertificateUpload(formData, isPdf, file, resolve);
            } else if (type == 'gradesheet') {
              handleGradesheetUpload(formData, isPdf, index + ind, file, resolve);
            }
          } else {
            resolve({
              docCopy: updateDocCopyAtIndex({ error: FileErrorMeaage[errMessage], isPdf }),
              index: index + ind
            });
          }
        };

        if (file instanceof Blob) {
          fileReader.readAsArrayBuffer(file);
        } else {
          console.error(
            `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
          );
        }
      });
    });
    Promise.all(promises)
      .then((files) => {
        /* Filters all files which are password protected*/
        const filesWithPassword = files.filter((file) => file?.isPasswordProtected);
        setPasswordProtectedFiles(filesWithPassword);
        setPasswordProtectedUploadedLength(filesWithPassword.length);

        if (type === 'certificate') {
          setDocUpload((prevDocUpload) => {
            return {
              ...prevDocUpload,
              certificate: {
                link: files[0]?.docCopy?.link,
                error: files[0]?.docCopy?.error,
                isPdf: files[0]?.docCopy?.isPdf,
                originalValidity: files[0]?.docCopy?.isValidDocument,
                isValidDocument: files[0]?.docCopy?.isValidDocument,
                documentTag: files[0]?.docCopy?.isValidDocument
                  ? files[0]?.docCopy?.documentTag
                  : '',
                originalTag: files[0]?.docCopy?.documentTag,
                extractedDetails: files[0]?.docCopy?.extractedDetails
              }
            };
          });
        } else if (type === 'gradesheet') {
          setDocUpload((prevDocUpload) => {
            const docCopy = [...prevDocUpload.gradeSheet];

            files &&
              files.forEach((file) => {
                docCopy[file?.index] = {
                  link: file?.docCopy?.link,
                  error: file?.docCopy?.error,
                  isPdf: file?.docCopy?.isPdf,
                  originalValidity: file?.docCopy?.isValidDocument,
                  isValidDocument: file?.docCopy?.isValidDocument,
                  documentTag: file?.docCopy?.isValidDocument ? file?.docCopy?.documentTag : '',
                  originalTag: file?.docCopy?.documentTag,
                  extractedDetails: file?.docCopy?.extractedDetails
                };
              });

            return {
              ...prevDocUpload,
              gradeSheet: [...docCopy]
            };
          });
        }
      })
      .finally(() => {
        setFileUploading(false);
      });
  };

  // For clearing error.
  const clearError = (type, index) => {
    if (type === 'certificate') {
      setDocUpload((prevDocUpload) => ({
        ...prevDocUpload,
        certificate: { link: '', error: '', loading: false }
      }));
    } else if (type === 'gradesheet') {
      const gradesheetCopy = [...docUpload.gradeSheet];
      gradesheetCopy.splice(index, 1);
      setDocUpload((prevDocUpload) => ({ ...prevDocUpload, gradeSheet: [...gradesheetCopy] }));
    }
  };

  // Handling preview of image
  const handleImagePreview = (url) => {
    setImagePreviewUrl(url || '');
    setShowImagePreviewModal((prevShowImagePreviewModal) => !prevShowImagePreviewModal);
  };

  // Handling delete of image
  const handleDeleteImage = (type, index) => {
    if (type === 'certificate') {
      setDocUpload((prevDocUpload) => ({
        ...prevDocUpload,
        certificate: {}
      }));
    } else if (type === 'gradesheet') {
      const gradesheetCopy = [...docUpload.gradeSheet];
      gradesheetCopy.splice(index, 1);

      setDocUpload((prevDocUpload) => ({ ...prevDocUpload, gradeSheet: [...gradesheetCopy] }));
    }
  };

  // For checking if any of the image uploaded is not having link, error, loading
  const isImageHavingError = () => {
    let isImageHavingError = false;
    for (let value of docUpload.gradeSheet) {
      if (!value.link || value.error || value.loading) {
        isImageHavingError = true;
        break;
      }
    }
    return isImageHavingError;
  };

  // Enabling save button
  const isSaveButtonEnable = () => {
    const step1 =
      educationCheck && has(educationCheck, 'step1') && !isEmpty(educationCheck.step1)
        ? educationCheck.step1
        : null;

    if (
      // For 10 and 12
      step1 &&
      marksheetArr.includes(step1?.educationLevel) &&
      docUpload.gradeSheet.length &&
      !isImageHavingError()
    ) {
      return true;
    } else if (
      // For certificate and gradesheet when completed graduation
      step1 &&
      bothCertificateMarksheet.includes(step1?.educationLevel) &&
      isDateCorrect &&
      docUpload.gradeSheet.length &&
      docUpload.certificate.link &&
      !isImageHavingError()
    ) {
      return true;
    } else if (
      // For certificate and gradesheet when pursuing graduation
      step1 &&
      bothCertificateMarksheet.includes(step1?.educationLevel) &&
      !isDateCorrect &&
      (noGradeSheetCheckbox ||
        (!noGradeSheetCheckbox && docUpload.gradeSheet.length && !isImageHavingError()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    const stateCopy = { ...docUpload };
    const gradeSheet = stateCopy.gradeSheet.length
      ? stateCopy.gradeSheet.map((value) => {
        return {
          link: value.link,
          isPdf: value.isPdf,
          password: value.password,
          originalTagValidity: value.isValidDocument,
          isValidDocument: value.isValidDocument,
          originalTag: value.documentTag,
          documentTag: value.documentTag,
          extractedDetails: value.extractedDetails
        };
      })
      : [];
    const certificate =
      !isEmpty(stateCopy.certificate) && stateCopy.certificate.link
        ? {
          link: stateCopy.certificate.link,
          isPdf: stateCopy.certificate.isPdf,
          password: stateCopy.certificate.password,
          originalTagValidity: stateCopy.certificate.isValidDocument,
          isValidDocument: stateCopy.certificate.isValidDocument,
          originalTag: stateCopy.certificate.documentTag,
          documentTag: stateCopy.certificate.documentTag,
          extractedDetails: stateCopy.certificate.extractedDetails
        }
        : {};

    const stepObject = {
      certificate,
      gradeSheet: gradeSheet
    };

    const educationCheck = [...props.bgvCachedData.cacheData.educationCheck];

    if (props.isEdit && !isEmpty(props.temporaryEducation)) {
      educationCheck[props.currentSavingIndex] = {
        ...props.temporaryEducation.saveObject.cacheData.educationCheck[props.currentSavingIndex]
      };
    }

    educationCheck[props.currentSavingIndex]['step2'] = { ...stepObject };
    educationCheck[props.currentSavingIndex]['packageCurrentSubstep'] = 1;

    const saveObject = toSnakeCase({
      ...props.bgvCachedData,
      cacheData: {
        ...props.bgvCachedData.cacheData,
        educationCheck: [...educationCheck]
      },
      currentState: '5'
    });

    const finalCacheObject = {
      saveObject
    };

    props
      .setBgvCache(finalCacheObject)
      .then(() => {
        props.onHide();

        // logger code
        const loggerObj = {
          message: `Education step2 is ${props.isEdit ? 'Edited' : 'Saved'}`,
          data: JSON.stringify(finalCacheObject),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Education details#2',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Handle camera certificate photo
  const handleCertificatePhoto = () => {
    setTakeCertificatePhoto((prevTakeCertificatePhoto) => !prevTakeCertificatePhoto);
    setIsCameraUploadError(false);
  };

  // Handle gradesheet photo
  const handleGradesheetPhoto = () => {
    setTakeGradesheetPhoto((prevTakeGradesheetPhoto) => !prevTakeGradesheetPhoto);
    setIsCameraUploadError(false);
  };

  // Render heading
  const renderHeading = (educationLevel) => {
    return (
      <p className={styles.degreeCertificateTitle}>
        {educationLevel && bothCertificateMarksheet.includes(educationLevel) && isDateCorrect ? (
          <>
            {'Add your degree certificate and all your mark sheets'} <span>*</span>
          </>
        ) : (
          <>
            {'Add your mark sheets'} <span>*</span>
          </>
        )}
      </p>
    );
  };

  // Render description
  const renderDescription = (educationLevel) => {
    return (
      <>
        <p className={styles.educationProofTitle}>{'Add your Education proof'}</p>
        {maxFileError && <p className={styles.maxFileError}>Maximum 10 files can be uploaded</p>}
        <p className={styles.educationProofDescription}>
          {educationLevel && bothCertificateMarksheet.includes(educationLevel) && isDateCorrect
            ? 'Please upload your degree certificate and all your mark sheets as proof. Upload .png, .jpg, .jpeg or pdf file only.'
            : 'Please upload your mark sheets as proof. Upload .png, .jpg, .jpeg or pdf file only.'}
        </p>
      </>
    );
  };

  // Enable gradesheet button
  const enableGradesheetBtn = () => {
    let isImageHavingError = false;

    if (isMobileOnly) {
      for (let value of docUpload.gradeSheet) {
        if (value?.error) {
          isImageHavingError = true;
          break;
        }
      }
    }
    return isImageHavingError;
  };

  // Handle initial state
  const handleInitialState = () => {
    let educationCheck = null,
      step1 = null;
    const docUploadCopy = { ...docUpload };

    if (props.isEdit && !isEmpty(props.temporaryEducation)) {
      educationCheck =
        props.temporaryEducation.saveObject.cacheData &&
          props.temporaryEducation.saveObject.cacheData.educationCheck &&
          props.temporaryEducation.saveObject.cacheData.educationCheck.length
          ? props.temporaryEducation.saveObject.cacheData.educationCheck[props.currentSavingIndex]
          : null;
    } else {
      educationCheck =
        props.bgvCachedData &&
          props.bgvCachedData.cacheData &&
          props.bgvCachedData.cacheData.educationCheck &&
          props.bgvCachedData.cacheData.educationCheck.length
          ? props.bgvCachedData.cacheData.educationCheck[props.currentSavingIndex]
          : null;
    }
    step1 = educationCheck && educationCheck.step1 ? educationCheck.step1 : null;
    if (
      educationCheck &&
      props?.educationCheck[props?.currentSavingIndex] &&
      props?.educationCheck[props?.currentSavingIndex]?.step2 &&
      !isEmpty(props?.educationCheck[props?.currentSavingIndex]?.step2) &&
      props?.educationCheck[props?.currentSavingIndex]?.step2?.gradeSheet?.length
    ) {
      const gradeSheet = props?.educationCheck[props?.currentSavingIndex]?.step2?.gradeSheet;
      docUploadCopy.gradeSheet = gradeSheet.map((item) => {
        return {
          link: item.link,
          error: '',
          loading: false,
          isPdf: item.isPdf || false,
          password: item.password,
          originalTagValidity: item?.originalTagValidity || item?.original_tag_validity,
          isValidDocument: item?.isValidDocument || item?.is_valid_document,
          originalTag: item?.originalTag || item?.original_tag,
          documentTag: item?.documentTag || item?.document_tag,
          extractedDetails: item?.extractedDetails || item?.extracted_details
        };
      });
    }
    if (
      educationCheck &&
      props?.educationCheck[props?.currentSavingIndex] &&
      props?.educationCheck[props?.currentSavingIndex]?.step2 &&
      !isEmpty(props?.educationCheck[props?.currentSavingIndex]?.step2) &&
      props?.educationCheck[props?.currentSavingIndex]?.step2?.certificate &&
      !isEmpty(props?.educationCheck[props?.currentSavingIndex]?.step2?.certificate)
    ) {
      const certificate = props?.educationCheck[props?.currentSavingIndex]?.step2?.certificate;
      docUploadCopy.certificate.link = certificate.link;

      docUploadCopy.certificate.isPdf = certificate.isPdf;
      docUploadCopy.certificate.password = certificate?.password;
      docUploadCopy.certificate.error = '';
      docUploadCopy.certificate.loading = false;
      docUploadCopy.certificate.originalTagValidity =
        certificate.originalTagValidity || certificate.original_tag_validity;
      docUploadCopy.certificate.isValidDocument =
        certificate.isValidDocument || certificate.is_valid_document;
      docUploadCopy.certificate.originalTag = certificate.originalTag || certificate.original_tag;
      docUploadCopy.certificate.documentTag = certificate.documentTag || certificate.document_tag;
      docUploadCopy.certificate.extractedDetails =
        certificate.extractedDetails || certificate.extracted_details;
    }

    setDocUpload({ ...docUploadCopy });
    setEducationCheck(educationCheck);

    if (step1) {
      const { endDate } = step1.datesAttended;

      moment(endDate, 'MM/YYYY') > moment() && setIsDateCorrect(false);
    }
  };

  // Modal hide
  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    if (uploadedType === 'certificate') {
      setDocUpload((prevDocUpload) => ({
        ...prevDocUpload,
        certificate: { link: '', error: '', loading: false }
      }));
      setIsCameraUploadError(true);
    } else if (uploadedType === 'gradesheet') {
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.gradeSheet];
        // If any of the password protected modal is closed, then we have save unprotected files/ unlocked files
        const passwordProtectedFilesIndices = passwordProtectedFiles.map((item) => item?.index);
        const newArray = docCopy.filter(
          (_, index) => !passwordProtectedFilesIndices.includes(index)
        );
        return {
          ...prevDocUpload,
          gradeSheet: newArray
        };
      });
    }
  };

  const skipFileHandler = (index) => {
    if (uploadedType === 'certificate') {
      setDocUpload((prevDocUpload) => ({
        ...prevDocUpload,
        certificate: { link: '', error: '', loading: false }
      }));
      setIsCameraUploadError(true);
    } else {
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.gradeSheet];
        docCopy.splice(index, 1);
        return {
          ...prevDocUpload,
          gradeSheet: docCopy
        };
      });
    }

    passwordProtectedFilesStateUpdate();
  };

  const passwordProtectedFilesStateUpdate = () => {
    setPasswordProtectedFiles((prevPasswordFiles) => {
      const remainingProtectedFiles = [...prevPasswordFiles];
      remainingProtectedFiles.pop();
      if (remainingProtectedFiles.length == 0) {
        setShowPasswordProtectedModal(false);
      }
      return remainingProtectedFiles;
    });
  };
  // Submit handler password modal
  const submitHandlerPasswordModal = async (password = '', link, index, validate) => {
    // Function for handling submit password when unable to unlock the file and when successfully saved the password
    if (validate) {
      const validationResult = await validateDoc(link);
      if (uploadedType === 'certificate') {
        setDocUpload((prevDocUpload) => ({
          ...prevDocUpload,
          certificate: {
            ...prevDocUpload.certificate,
            link,
            password,
            originalValidity: validationResult?.isValidDocument,
            isValidDocument: validationResult?.isValidDocument,
            documentTag: validationResult?.isValidDocument ? validationResult?.documentTag : '',
            originalTag: validationResult?.documentTag,
            extractedDetails: validationResult?.extractedDetails
          }
        }));
      } else if (uploadedType === 'gradesheet') {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.gradeSheet];
          docCopy[index] = {
            ...docCopy[index],
            link,
            password,
            originalValidity: validationResult?.isValidDocument,
            isValidDocument: validationResult?.isValidDocument,
            documentTag: validationResult?.isValidDocument ? validationResult?.documentTag : '',
            originalTag: validationResult?.documentTag,
            extractedDetails: validationResult?.extractedDetails
          };
          return {
            ...prevDocUpload,
            gradeSheet: docCopy
          };
        });
      }
    } else {
      if (uploadedType === 'certificate') {
        setDocUpload((prevDocUpload) => ({
          ...prevDocUpload,
          certificate: { ...updateDocCopyAtIndex({ link, password }) }
        }));
      } else if (uploadedType === 'gradesheet') {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.gradeSheet];
          docCopy[index] = updateDocCopyAtIndex({ link, password });
          return {
            ...prevDocUpload,
            gradeSheet: docCopy
          };
        });
      }
    }
    passwordProtectedFilesStateUpdate();
  };

  const renderGradeSheetHavingLength = () => {
    return docUpload.gradeSheet.map((item, index) => {
      return item?.error ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={10}
          key={`grade_sheet_${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderErrorUI
            handleClick={clearError}
            errorText={item?.error}
            index={index}
            type='gradesheet'
          />
        </Col>
      ) : item?.loading ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={10}
          key={`grade_sheet_${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <div className={styles.certificateInputFileContainer}>
            <Spinner animation='border' variant='primary' />
          </div>
        </Col>
      ) : item?.link ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={10}
          key={`grade_sheet_${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderUploadedFile
            allItems={docUpload.gradeSheet}
            item={item}
            handleImagePreview={handleImagePreview}
            handleDeleteImage={handleDeleteImage}
            type='gradesheet'
            index={index}
            isPdf={item?.isPdf}
          />
          {isEditing === item.link ? (
            <>
              {showOtherField ? (
                <Input
                  className='border rounded p-2'
                  value={otherFieldValue}
                  type='text'
                  placeholder='What document is this?'
                  onChange={(e) => setOtherFieldValue(e.target.value)}
                  onBlur={() => {
                    if (otherFieldValue.trim()) {
                      const tempDocUpload = [...docUpload.gradeSheet];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: otherFieldValue,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, gradeSheet: tempDocUpload });
                    }
                    setShowOtherField(false);
                    setIsEditing(null);
                    setOtherFieldValue('');
                  }}
                  autoFocus={true}
                />
              ) : (
                <Select
                  className='mb-2'
                  options={MARKSHEET_DROPDOWN}
                  menuPlacement='top'
                  value={
                    item.documentTag ? { label: item.documentTag, value: item.documentTag } : null
                  }
                  onChange={(value) => {
                    if (value.value === 'Other') {
                      setShowOtherField(true);
                    } else {
                      const tempDocUpload = [...docUpload.gradeSheet];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: value.label,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, gradeSheet: tempDocUpload });
                      setIsEditing(null);
                    }
                  }}
                  autoFocus={true}
                  blurInputOnSelect={false}
                  onBlur={() => setIsEditing(null)}
                />
              )}
            </>
          ) : (
            // Show the badge and edit button when not editing
            <div className='mb-2'>
              <Badge pill variant={item.documentTag ? 'success' : 'warning'} className='mr-1'>
                <div style={{ textTransform: 'capitalize' }}>
                  {item.documentTag || 'Invalid Doc'}
                </div>
              </Badge>
              <Image
                className={styles.editIcon}
                name='BgvIdEdit.png'
                data-testid='edit-button'
                onClick={() => setIsEditing(item.link)}
              />
            </div>
          )}
        </Col>
      ) : (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={10}
          key={`grade_sheet_${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <label className={styles.certificateInputFileContainer}>
            <div>
              <Image name='certificate_upload_enable.png' />
              <p>{'Add your mark sheet'}</p>
              <Form.Control
                id='formControlsFile'
                accept='image/*,application/pdf'
                type='file'
                label='File'
                multiple
                onChange={(e) => handleFileSelect(e, 'gradesheet', index)}
              />
            </div>
          </label>
        </Col>
      );
    });
  };

  // Render Upload Grade Sheet
  const renderUploadGradeSheet = () => {
    return (
      <Col lg={5} md={6} sm={10} xs={10} className={isMobileOnly ? styles.uploadContainer : null}>
        <label
          className={cn(
            styles.certificateInputFileContainer,
            fileUploading ? styles.disabledInputFileContainer : ''
          )}
        >
          <div>
            <Image name='certificate_upload_enable.png' />
            <p>{'Add your mark sheet'}</p>
            <Form.Control
              id='formControlsFile'
              accept='image/*,application/pdf'
              type='file'
              label='File'
              disabled={fileUploading}
              multiple
              onChange={(e) => handleFileSelect(e, 'gradesheet', docUpload.gradeSheet.length)}
            />
          </div>
        </label>
      </Col>
    );
  };

  const handleIgnoreAndSave = (e) => {
    setShowWarningModal(false);
    handleSubmit(e);
  };

  const DocumentWarningModal = () => (
    <Modal size={440} show radius={12} backdrop={'static'} centered>
      <div className={styles.bgvEmpDocumentWarning}>
        <span className={styles.warningModalCloseButton} onClick={() => setShowWarningModal(false)}>
          <CloseButton />
        </span>
        <span>
          <img src={ModalWarningSvg} alt='warning-svg' />
        </span>
        <span className={styles.modalWarningHeader}>Oops! AI detected the wrong document.</span>
        <span>It seems the file isn’t what we expected. Please check and try again.</span>
        <div className={styles.modalWarningButtonContain}>
          <Button className={styles.ignoreSaveButton} onClick={handleIgnoreAndSave}>
            Ignore & Save
          </Button>
          <Button className={styles.modalWarningButton} onClick={() => setShowWarningModal(false)}>
            Upload again
          </Button>
        </div>
      </div>
    </Modal>
  );

  async function handleNextClick(e) {
    const validCertifates = docUpload?.certificate?.isValidDocument;
    const inValidGradesheets = docUpload?.gradeSheet?.filter((sheet) => !sheet.isValidDocument);

    const isBothDocs =
      educationCheck?.step1?.educationLevel &&
      bothCertificateMarksheet.includes(educationCheck?.step1?.educationLevel) &&
      isDateCorrect;

    if (
      (isBothDocs && validCertifates && !inValidGradesheets.length) ||
      (!isBothDocs && !inValidGradesheets.length)
    ) {
      handleSubmit(e);
    } else {
      setShowWarningModal(true);
    }
  }

  return (
    <>
      {showWarningModal && <DocumentWarningModal />}
      <Modal
        size={showPasswordProtectedModal ? 'sm' : 'lg'}
        show={props.show}
        onHide={props.onHide}
        backdropClassName={'customBGVModalBackdrop'}
        dialogClassName='bgvModalDialog'
        className='bgvModal'
        centered
      >
        <div style={showPasswordProtectedModal ? { display: 'none' } : null}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <div className={styles.educationModalTitle}>
                {props.isSpecific ? (
                  <span>
                    {props.isEdit
                      ? `Edit ${obj[educationCheck?.step1?.educationLevel]} details`
                      : `Add ${obj[educationCheck?.step1?.educationLevel]} details`}
                  </span>
                ) : (
                  <span>{props.isEdit ? 'Edit Education' : 'Add Education'}</span>
                )}
              </div>
              <div className={styles.educationModalSubTitle}>
                <span>{`Step ${props.currentSubStep} of ${props.totalStep}`}</span>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={styles.addEditEducationModalBody}>
            <Row className={styles.educationProofContainer}>
              <Col lg={12} md={12} sm={12} xs={12}>
                {renderDescription(educationCheck?.step1?.educationLevel)}
              </Col>
            </Row>{' '}
            <Row className={styles.degreeCertificateContainer}>
              <Col lg={12} md={12} sm={12} xs={12}>
                {renderHeading(educationCheck?.step1?.educationLevel)}
              </Col>
              <Col
                lg={10}
                md={12}
                sm={12}
                xs={12}
                className={isMobileOnly ? styles.uploadParentContainer : null}
              >
                <Row style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}>
                  {/* certificate section */}
                  {educationCheck?.step1?.educationLevel &&
                    bothCertificateMarksheet.includes(educationCheck?.step1?.educationLevel) &&
                    isDateCorrect ? (
                    <>
                      {isMobileOnly ? (
                        <Col sm={10} xs={10}>
                          <Row>
                            <Col sm={12} xs={12} className={styles.takePhotoBtnContainer}>
                              <Button
                                disabled={docUpload.certificate.error || docUpload.certificate.link}
                                className={styles.takePhotoBtn}
                                onClick={handleCertificatePhoto}
                              >
                                Take Photo of degree certifcate
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {isEmpty(docUpload.certificate) || !docUpload.certificate.link ? (
                        <Col
                          lg={5}
                          md={6}
                          sm={10}
                          xs={10}
                          className={isMobileOnly ? styles.uploadContainer : null}
                        >
                          {docUpload.certificate.error ? (
                            <RenderErrorUI
                              handleClick={clearError}
                              errorText={docUpload.certificate.error}
                              index={null}
                              type='certificate'
                            />
                          ) : docUpload.certificate.loading ? (
                            <div className={styles.certificateInputFileContainer}>
                              <Spinner animation='border' variant='primary' />
                            </div>
                          ) : (
                            <>
                              <label
                                className={cn(
                                  styles.certificateInputFileContainer,
                                  fileUploading ? styles.disabledInputFileContainer : ''
                                )}
                              >
                                <div>
                                  <Image name='certificate_upload_enable.png' />
                                  <p>Add your degree certificate</p>
                                  <Form.Control
                                    id='formControlsFile'
                                    className={styles.attachDocBtn}
                                    accept='image/*,application/pdf'
                                    type='file'
                                    disabled={fileUploading}
                                    label='File'
                                    onChange={(e) => handleFileSelect(e, 'certificate')}
                                  />
                                </div>
                              </label>
                            </>
                          )}
                        </Col>
                      ) : (
                        // When file is uploaded successfully for certificate
                        <Col
                          lg={5}
                          md={6}
                          sm={10}
                          xs={10}
                          className={isMobileOnly ? styles.uploadContainer : null}
                        >
                          <RenderUploadedFile
                            item={{
                              link: docUpload.certificate.link,
                              password: docUpload.certificate.password
                            }}
                            handleImagePreview={handleImagePreview}
                            handleDeleteImage={handleDeleteImage}
                            type='certificate'
                            index={null}
                            isPdf={docUpload.certificate.isPdf}
                          />
                          {isEditing === docUpload.certificate.link ? (
                            <Select
                              className='mb-2'
                              options={DEGREE_DROPDOWN}
                              menuPlacement='top'
                              value={{
                                label: docUpload.certificate.documentTag,
                                value: docUpload.certificate.documentTag
                              }}
                              onBlur={() => setIsEditing(null)}
                              autoFocus={true}
                              blurInputOnSelect={true}
                              onChange={(value) => {
                                const tempDocUpload = {
                                  ...docUpload.certificate,
                                  documentTag: value.value,
                                  isValidDocument: true
                                };

                                setDocUpload({ ...docUpload, certificate: { ...tempDocUpload } });
                              }}
                            />
                          ) : (
                            <div className='mb-2'>
                              <Badge
                                pill
                                variant={docUpload.certificate.documentTag ? 'success' : 'warning'}
                                className='mr-1'
                              >
                                <div style={{ textTransform: 'capitalize' }}>
                                  {docUpload.certificate.documentTag || 'Invalid Doc'}
                                </div>
                              </Badge>
                              <Image
                                className={styles.editIcon}
                                name='BgvIdEdit.png'
                                data-testid='edit-button'
                                onClick={() => setIsEditing(docUpload.certificate.link)}
                              />
                            </div>
                          )}
                        </Col>
                      )}
                    </>
                  ) : null}

                  {/* Grade Sheet Section */}

                  {isMobileOnly &&
                    educationCheck &&
                    educationCheck?.step1 &&
                    educationCheck?.step1.educationLevel ? (
                    <Col sm={10} xs={10}>
                      <Row>
                        <Col sm={12} xs={12} className={styles.takePhotoBtnContainer}>
                          <Button
                            className={styles.takePhotoBtn}
                            onClick={() => handleGradesheetPhoto()}
                            disabled={enableGradesheetBtn() && docUpload.gradeSheet.length < 10}
                          >
                            Take Photo of mark sheets
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}

                  {educationCheck && educationCheck?.step1 ? (
                    <>
                      {docUpload.gradeSheet.length ? renderGradeSheetHavingLength() : null}

                      {docUpload.gradeSheet.length < 10 &&
                        !isImageHavingError() &&
                        !noGradeSheetCheckbox
                        ? renderUploadGradeSheet()
                        : null}
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
            {showImagePreviewModal && (
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <ImagePreviewModal
                    showImagePreviewModal={showImagePreviewModal}
                    handleImagePreview={handleImagePreview}
                    imagePreviewUrl={imagePreviewUrl}
                  />
                </Col>
              </Row>
            )}
            {isMobileOnly && takeCertificatePhoto && (
              <BGVCamera
                type='single'
                showBgvCameraModal={takeCertificatePhoto}
                handleCameraModal={handleCertificatePhoto}
                handleSingleUpload={(formData, isPdf, resolve, file) => {
                  handleCertificateUpload(formData, isPdf, file, resolve, true);
                }}
                isCameraUploadError={isCameraUploadError}
              />
            )}
            {isMobileOnly && takeGradesheetPhoto && (
              <BGVCamera
                type='multiple'
                showBgvCameraModal={takeGradesheetPhoto}
                handleCameraModal={handleGradesheetPhoto}
                handleMultipleUpload={(formData, isPdf, index, setCamState, file) => {
                  handleGradesheetUpload(formData, isPdf, index, file, setCamState, true);
                }}
                isCameraUploadError={isCameraUploadError}
                index={
                  docUpload.gradeSheet.length && !isImageHavingError()
                    ? docUpload.gradeSheet.length
                    : docUpload.gradeSheet.length && isImageHavingError()
                      ? docUpload.gradeSheet.length - 1
                      : docUpload.gradeSheet.length
                }
              />
            )}
          </Modal.Body>

          <Modal.Footer className={styles.educationSubmitBtn}>
            <Button
              className={'bgvModalSecondaryBtn'}
              onClick={(e) => {
                props.prevSubStep();
              }}
            >
              {'Go Back'}
            </Button>
            <Button
              className={'bgvModalPrimaryBtn'}
              disabled={!isSaveButtonEnable()}
              type='button'
              onClick={(e) => handleNextClick(e)}
            >
              {'Save'}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'education'}
          SubOnHide={modalHide}
          skipFileHandler={skipFileHandler}
          passwordProtectedUploadedLength={passwordProtectedUploadedLength}
          passwordProtectedFiles={passwordProtectedFiles}
          isValidatingDoc={isValidatingDoc}
        />
      )}
    </>
  );
};

export default AddEditUpload;
