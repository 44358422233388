// React and other libraries imports
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, CloseButton, Badge } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { pdfjs } from 'react-pdf';
import Select from 'react-select';

// Core components and utils imports
import Image from '../../../../../../../core-components/Image';
import ModalWarningSvg from '../../../../../../../images/warning.svg';
import { empDropdownArray } from '../../../../../../../utils/commonConstant';
import {
  FileErrorMeaage,
  formatDocType,
  getFileHeader,
  readFileAsArrayBuffer,
  validateImage
} from '../../../../../../../utils/utilities';
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import {
  RenderErrorUI,
  RenderUploadedFile
} from '../../../Education/EducationModals/AddEditUpload/CommonUploadComponent';
import PasswordProtectedModal from '../../../PasswordProtectedModal';

// Styles import
import styles from './FilesStep.module.scss';
import { Description } from '@material-ui/icons';
import { Input } from '@material-ui/core';

const FilesStep = ({
  show,
  onHide,
  subPrevStep,
  setFieldValue,
  handleSubmit,
  values,
  empDocType,
  educationGradesheetUpload,
  deleteGradesheetUpload,
  isSubmitting,
  isEdit,
  validateDocument
}) => {
  const [docUpload, setDocUpload] = useState({
    proof_doc: []
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [isCamHavingError, setIsCamHavingError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [maxFileError, setMaxFileError] = useState(false);
  const [passwordProtectedFiles, setPasswordProtectedFiles] = useState([]);
  const [passwordProtectedUploadedLength, setPasswordProtectedUploadedLength] = useState(0); // Store initial password protected length
  const [showEmpWarningModal, setShowEmpWarningModal] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [isValidatingDoc, setIsValidatingDoc] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherFieldValue, setOtherFieldValue] = useState('');

  useEffect(() => {
    if (passwordProtectedFiles.length) {
      setShowPasswordProtectedModal(true);
    }
  }, [passwordProtectedFiles]);

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      // If any of the password protected modal is closed, then we have save unprotected files/ unlocked files
      const passwordProtectedFilesIndices = passwordProtectedFiles.map((item) => item?.index);
      const newArray = docCopy.filter((_, index) => !passwordProtectedFilesIndices.includes(index));
      return {
        ...prevDocUpload,
        proof_doc: newArray
      };
    });
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const skipFileHandler = (index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy.splice(index, 1);
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });

    passwordProtectedFilesStateUpdate();
  };

  const submitHandlerPasswordModal = async (password = '', link, index, validate) => {
    if (validate) {
      const validationResult = await validateDoc(link);
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.proof_doc];
        docCopy[index] = {
          ...docCopy[index],
          link,
          password,
          originalValidity: validationResult?.isValidDocument,
          isValidDocument: validationResult?.isValidDocument,
          documentTag: validationResult?.isValidDocument ? validationResult?.documentTag : '',
          originalTag: validationResult?.documentTag,
          extractedDetails: validationResult?.extractedDetails
        };
        return {
          ...prevDocUpload,
          proof_doc: docCopy
        };
      });
    } else {
      setDocUpload((prevDocUpload) => {
        const docCopy = [...prevDocUpload.proof_doc];
        docCopy[index] = updateDocCopyAtIndex({ link, password });
        return {
          ...prevDocUpload,
          proof_doc: docCopy
        };
      });
    }
    passwordProtectedFilesStateUpdate();
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const uploadDoc = async (formData, isPdf) => {
    try {
      const response = await educationGradesheetUpload({ formData, isPdf });
      return { link: response.link, error: '', loading: false, isPdf };
    } catch (error) {
      return {
        link: '',
        error: 'Upload failed. File format not valid.',
        loading: false,
        isPdf: isPdf,
        password: ''
      };
    }
  };

  const processPdfDocument = async (pdfData, file, resolve, docCopy, index) => {
    try {
      const loadingTask = pdfjs.getDocument({ data: pdfData, password: '' });
      await loadingTask.promise;

      let validationResult;

      if (!docCopy.error) {
        validationResult = await validateDoc(docCopy.link);
      }

      if (validationResult) {
        resolve({ docCopy: { ...docCopy, ...validationResult }, index });
      } else {
        resolve({ docCopy, index });
      }
    } catch (error) {
      if (error.message === 'No password given') {
        resolve({
          docCopy: { ...docCopy, fileName: file.name },
          index,
          isPasswordProtected: true
        });
      }
    }
  };

  const handleCameraPhotoUpload = (res, setDocUpload) => {
    handleCamPhoto();
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy.push({
        link: res?.link,
        error: false,
        isPdf: false
      });
      return {
        ...prevDocUpload,
        proof_doc: [...docCopy]
      };
    });
  };

  const validateDoc = async (url) => {
    // Set up form data for validation
    const formData = new FormData();
    formData.append('prompt_name', 'employmentPromptID');
    formData.append('url', url);

    try {
      setIsValidatingDoc(true);
      const response = await validateDocument(formData);

      // Check if validation was successful
      if (response.ok) {
        setIsValidatingDoc(false);
        return response?.aiResponse;
      } else {
        setIsValidatingDoc(false);
        console.error('Validation failed with response:', response);
        return null;
      }
    } catch (error) {
      setIsValidatingDoc(false);
      console.error('Error validating document:', error);
      return null;
    }
  };

  // Main function that coordinates the file upload process
  const handleFileUpload = async (formData, isPdf, index, file, resolve, isCam = false) => {
    try {
      const fileData = await readFileAsArrayBuffer(file);

      let docCopy = await uploadDoc(formData, isPdf);

      if (!docCopy.error && !isPdf) {
        const validationResult = await validateDoc(docCopy.link);
        docCopy = { ...docCopy, ...validationResult };
      }

      if (isPdf) {
        await processPdfDocument(fileData, file, resolve, docCopy, index);
      } else {
        resolve(isCam ? false : { docCopy, index });
        if (isCam) {
          handleCameraPhotoUpload(docCopy, setDocUpload);
        }
      }
    } catch (error) {
      resolve({
        link: '',
        error: error.message || 'An unexpected error occurred.',
        loading: false,
        isPdf,
        password: ''
      });
    }
  };

  const handleFileSelect = async (e, type, index) => {
    const numberOfFiles = docUpload.proof_doc.length + e.target.files.length;

    if (numberOfFiles > 10) {
      setMaxFileError(true);
    } else {
      setMaxFileError(false);
      const promises = Array.from(e.target.files).map((file, ind) => {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];
          docCopy[index] = updateDocCopyAtIndex({ error: '', loading: true });
          return {
            ...prevDocUpload,
            proof_doc: docCopy
          };
        });
        return new Promise((resolve, reject) => {
          let fileType = '';
          var blob = file;
          var fileReader = new FileReader();
          fileReader.onloadend = () => {
            var arr = new Uint8Array(fileReader.result).subarray(0, 4);
            var header = '';
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Check the file signature against known types
            fileType = getFileHeader(header);
            const errMessage = validateImage(blob, fileType);
            const isPdf = fileType === 'application/pdf' ? true : false;
            if (!errMessage) {
              let formData = new FormData();
              formData.append(`file`, blob);
              formData.append(`file_type`, 'CHECK_DOCUMENT');
              handleFileUpload(formData, isPdf, index + ind, blob, resolve);
            } else {
              resolve({
                docCopy: updateDocCopyAtIndex({ error: FileErrorMeaage[errMessage], isPdf }),
                index: index + ind
              });
            }
          };

          if (blob instanceof Blob) {
            /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
            fileReader.readAsArrayBuffer(blob);
          } else {
            console.error(
              `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
            );
          }
        });
      });
      Promise.all(promises).then((files) => {
        /* Filters all files which are password protected*/
        const filesWithPassword = files.filter((file) => file?.isPasswordProtected);
        setPasswordProtectedFiles(filesWithPassword);
        setPasswordProtectedUploadedLength(filesWithPassword.length);

        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];

          files?.forEach((file) => {
            docCopy[file?.index] = {
              link: file?.docCopy?.link,
              error: file?.docCopy?.error,
              isPdf: file?.docCopy?.isPdf,
              originalValidity: file?.docCopy?.isValidDocument,
              isValidDocument: file?.docCopy?.isValidDocument,
              documentTag: file?.docCopy?.isValidDocument ? file?.docCopy?.documentTag : '',
              originalTag: file?.docCopy?.documentTag,
              extractedDetails: file?.docCopy?.extractedDetails
            };
          });

          return {
            ...prevDocUpload,
            proof_doc: [...docCopy]
          };
        });
      });
    }
  };

  const clearError = (type, index) => {
    const empdocCopy = [...docUpload.proof_doc];
    empdocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...empdocCopy]
    });
  };

  const isImageHavingError = () => {
    let isImageHavingError = false;
    for (let value of docUpload.proof_doc) {
      if (!value?.link || value?.error || value?.loading) {
        isImageHavingError = true;
        break;
      }
    }
    return isImageHavingError;
  };

  const handleImagePreview = (url) => {
    setImagePreviewUrl(url || '');
    setShowImagePreviewModal(!showImagePreviewModal);
  };

  const handleDeleteImage = (type, index) => {
    const empdocCopy = [...docUpload.proof_doc];
    empdocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...empdocCopy]
    });

    deleteGradesheetUpload(empdocCopy);
  };

  const handleCamPhoto = () => {
    setTakePhoto(!takePhoto);
    setIsCamHavingError(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let cloneDocs = [];

    for (let value of docUpload.proof_doc) {
      if (value.link) {
        cloneDocs.push({
          link: value.link,
          isPdf: value.isPdf,
          password: value.password,
          originalTagValidity: value.isValidDocument,
          isValidDocument: value.isValidDocument,
          originalTag: value.documentTag,
          documentTag: value.documentTag,
          extractedDetails: value.extractedDetails
        });
      }
    }
    setFieldValue('proof_doc', cloneDocs);
    handleSubmit();
  };

  const enablePhotoBtn = () => {
    let isImageHavingError = false;

    if (isMobileOnly) {
      for (let value of docUpload.proof_doc) {
        if (value?.error) {
          isImageHavingError = true;
          break;
        }
      }
    }
    return isImageHavingError;
  };

  useEffect(() => {
    const docUploadCopy = Object.assign({}, { ...docUpload });
    let documents = values.proof_doc;

    if (empDocType) {
      setFieldValue('doc_type', empDocType);
    }
    if (documents.length > 0) {
      docUploadCopy.proof_doc = documents.map((item) => {
        return {
          link: item?.link,
          error: '',
          loading: false,
          isPdf: item?.isPdf || item?.is_pdf,
          password: item?.password,
          originalTagValidity: item?.originalTagValidity || item?.original_tag_validity,
          isValidDocument: item?.isValidDocument || item?.is_valid_document,
          originalTag: item?.originalTag || item?.original_tag,
          documentTag: item?.documentTag || item?.document_tag,
          extractedDetails: item?.extractedDetails || item?.extracted_details
        };
      });
    }

    setDocUpload({ ...docUploadCopy });
  }, []);

  /* Utility/common functions */
  const updateDocCopyAtIndex = ({
    link = '',
    error = null,
    loading = false,
    isPdf = true,
    password = null
  }) => {
    return {
      link,
      error,
      loading,
      isPdf,
      password
    };
  };

  const passwordProtectedFilesStateUpdate = () => {
    setPasswordProtectedFiles((prevPasswordFiles) => {
      const remainingProtectedFiles = [...prevPasswordFiles];
      remainingProtectedFiles.pop();
      if (remainingProtectedFiles.length == 0) {
        setShowPasswordProtectedModal(false);
      }
      return remainingProtectedFiles;
    });
  };

  const renderEmpDocHavingLength = () => {
    return docUpload.proof_doc.map((item, index) => {
      return item?.error ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderErrorUI
            handleClick={clearError}
            errorText={item.error}
            index={index}
            type='empdoc'
          />
        </Col>
      ) : item?.loading ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <div className={styles.certificateInputFileContainer}>
            <Spinner animation='border' variant='primary' />
          </div>
        </Col>
      ) : item?.link ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={12}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderUploadedFile
            allItems={docUpload.proof_doc}
            item={item}
            handleImagePreview={handleImagePreview}
            handleDeleteImage={handleDeleteImage}
            type='empdoc'
            index={index}
            isPdf={item.isPdf}
          />
          {isEditing === item.link ? (
            <>
              {showOtherField ? (
                <Input
                  className='border rounded p-2'
                  value={otherFieldValue}
                  type='text'
                  placeholder='What document is this?'
                  onChange={(e) => setOtherFieldValue(e.target.value)}
                  onBlur={() => {
                    if (otherFieldValue.trim()) {
                      const tempDocUpload = [...docUpload.proof_doc];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: otherFieldValue,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, proof_doc: tempDocUpload });
                    }
                    setShowOtherField(false);
                    setIsEditing(null);
                    setOtherFieldValue('');
                  }}
                  autoFocus={true}
                />
              ) : (
                <Select
                  className='mb-2'
                  options={[...empDropdownArray, { label: 'Other', value: 'other' }]}
                  menuPlacement='top'
                  value={
                    [...empDropdownArray, { label: 'Other', value: 'other' }].find(
                      (object) => formatDocType(object.label) === formatDocType(item.documentTag)
                    ) || null
                  }
                  onChange={(value) => {
                    if (value.value === 'other') {
                      setShowOtherField(true);
                    } else {
                      const tempDocUpload = [...docUpload.proof_doc];
                      tempDocUpload[index] = {
                        ...tempDocUpload[index],
                        documentTag: value.label,
                        isValidDocument: true
                      };
                      setDocUpload({ ...docUpload, proof_doc: tempDocUpload });
                      setIsEditing(null);
                    }
                  }}
                  autoFocus={true}
                  blurInputOnSelect={false}
                  onBlur={() => setIsEditing(null)}
                />
              )}
            </>
          ) : (
            // Show the badge and edit button when not editing
            <div className='mb-2'>
              <Badge pill variant={item.documentTag ? 'success' : 'warning'} className='mr-1'>
                <div style={{ textTransform: 'capitalize' }}>
                  {item.documentTag || 'Invalid Doc'}
                </div>
              </Badge>
              <Image
                className={styles.editIcon}
                name='BgvIdEdit.png'
                data-testid='edit-button'
                onClick={() => setIsEditing(item.link)}
              />
            </div>
          )}
        </Col>
      ) : (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <label className={styles.certificateInputFileContainer}>
            <div>
              <Image name='certificate_upload_enable.png' />
              <p>Add your employment proof</p>
              <Form.Control
                id='formControlsFile'
                accept='image/*,application/pdf'
                type='file'
                label='File'
                multiple
                onChange={(e) => handleFileSelect(e, 'empdoc', index)}
              />
            </div>
          </label>
        </Col>
      );
    });
  };

  const renderUploadEmpDoc = () => {
    return (
      <Col lg={5} md={6} sm={11} xs={11}>
        <label className={styles.certificateInputFileContainer}>
          <div>
            <Image name='certificate_upload_enable.png' />
            <p>Add your employment proof</p>
            <Form.Control
              id='formControlsFile'
              accept='image/*,application/pdf'
              type='file'
              label='File'
              multiple
              onChange={(e) => handleFileSelect(e, 'empdoc', docUpload.proof_doc.length)}
            />
          </div>
        </label>
      </Col>
    );
  };

  const getTitle = () => {
    const restOfTitle = values.companyName ? `${values.companyName} details` : ' Employment';
    return `${!isEdit ? 'Add' : 'Edit'} ${restOfTitle}`;
  };

  const isValidForm = docUpload.proof_doc.length > 0 && !isImageHavingError();

  const handleAddressDocumentWarningModalClick = () => {
    setShowEmpWarningModal((prev) => !prev);
  };
  const handleIgnoreAndSave = (e) => {
    setShowEmpWarningModal(false);
    handleFormSubmit(e);
  };

  async function handleNextClick(e) {
    const validDocs = docUpload.proof_doc.filter((item) => item.isValidDocument);

    if (validDocs.length) {
      handleFormSubmit(e);
    } else {
      setShowEmpWarningModal(true);
    }
  }

  const DocumentWarningModal = () => (
    <Modal size={440} show radius={12} backdrop={'static'} centered>
      <Modal.Header closeButton onHide={handleAddressDocumentWarningModalClick} />
      <div className={styles.bgvEmpDocumentWarning}>
        <span>
          <img src={ModalWarningSvg} alt='warning-svg' />
        </span>
        <span className={styles.modalWarningHeader}>Oops! AI detected the wrong document.</span>
        <span>It seems the file isn’t what we expected. Please check and try again.</span>
        <div className={styles.modalWarningButtonContain}>
          <Button className={styles.ignoreSaveButton} onClick={handleIgnoreAndSave}>
            Ignore & Save
          </Button>
          <Button
            className={styles.modalWarningButton}
            onClick={handleAddressDocumentWarningModalClick}
          >
            Upload again
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {showEmpWarningModal ? <DocumentWarningModal /> : null}

      <Modal
        size={showPasswordProtectedModal ? 'sm' : 'lg'}
        show={show}
        onHide={onHide}
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='bgvModalDialog'
        className='bgvModal'
        backdropClassName={'customBGVModalBackdrop'}
        centered
      >
        <div style={showPasswordProtectedModal ? { display: 'none' } : null}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <div className={styles.employmentModalTitle}>
                <span> {getTitle()}</span>
              </div>
              <div className={styles.employmentModalSubTitle}>
                <span>{`Step 2 of 3`}</span>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Form>
            <Modal.Body className={styles.addEditEducationModalBody}>
              <Row className={styles.employmentProofContainer}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <p className={styles.employmentProofTitle}>Add your Employment proof</p>
                </Col>
              </Row>

              <div className={styles.documentContainer}>
                <div className={styles.documentBox}>
                  <div className={styles.documentHeader}>
                    <Description color='primary' fontSize='small' />
                    Documents needed as proof
                    <span>(JPG, PNG, PDF)</span>
                  </div>
                  <div className={styles.listContainer}>
                    {empDropdownArray.map((doc, index) => (
                      <React.Fragment key={index}>
                        <div className={styles.documentItem}>
                          <div className={styles.documentDot}></div>
                          {doc.label}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <Row className={styles.degreeCertificateContainer}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <p className={styles.degreeCertificateTitle}>
                    Add your employment proof <span>*</span>
                  </p>
                  {maxFileError && (
                    <p className={styles.maxFileError}>Maximum 10 files can be uploaded</p>
                  )}
                </Col>
                {isMobileOnly ? (
                  <Col sm={12} xs={12}>
                    <Row>
                      <Col sm={11} xs={11} className={styles.takePhotoBtnContainer}>
                        <Button
                          className={styles.takePhotoBtn}
                          onClick={() => handleCamPhoto()}
                          disabled={enablePhotoBtn() && docUpload.proof_doc.length}
                        >
                          Take Photo of proof
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ) : null}

                <Col lg={10} md={12} sm={12} xs={12}>
                  <Row style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}>
                    <>
                      {docUpload.proof_doc.length ? renderEmpDocHavingLength() : null}
                      {docUpload.proof_doc.length < 10 && !isImageHavingError()
                        ? renderUploadEmpDoc()
                        : null}
                    </>
                  </Row>
                </Col>
              </Row>
              {showImagePreviewModal && (
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ImagePreviewModal
                      showImagePreviewModal={showImagePreviewModal}
                      handleImagePreview={handleImagePreview}
                      imagePreviewUrl={imagePreviewUrl}
                    />
                  </Col>
                </Row>
              )}
              {isMobileOnly && takePhoto && (
                <BGVCamera
                  type='multiple'
                  showBgvCameraModal={takePhoto}
                  handleCameraModal={handleCamPhoto}
                  //handleMultipleUpload={handleFileUpload}
                  handleMultipleUpload={(formData, isPdf, index, setCamState, file) => {
                    handleFileUpload(formData, isPdf, index, file, setCamState, true);
                  }}
                  isCameraUploadError={isCamHavingError}
                  index={
                    docUpload.proof_doc.length && !isImageHavingError()
                      ? docUpload.proof_doc.length
                      : docUpload.proof_doc.length && isImageHavingError()
                        ? docUpload.proof_doc.length - 1
                        : docUpload.proof_doc.length
                  }
                />
              )}
            </Modal.Body>
            <Modal.Footer className={styles.employmentSubmitBtn}>
              <Button className={'bgvModalSecondaryBtn'} onClick={(e) => subPrevStep()}>
                Go Back
              </Button>
              <Button
                onClick={(e) => handleNextClick(e)}
                className={'bgvModalPrimaryBtn'}
                disabled={isSubmitting || !isValidForm}
              >
                Next
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'employment'}
          SubOnHide={modalHide}
          skipFileHandler={skipFileHandler}
          passwordProtectedUploadedLength={passwordProtectedUploadedLength}
          passwordProtectedFiles={passwordProtectedFiles}
          isValidatingDoc={isValidatingDoc}
        />
      )}
    </>
  );
};

export default FilesStep;
